import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import TextField from '@material-ui/core/TextField';
import { TextInput } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutline';
import SvgIcon from '@material-ui/core/SvgIcon';
import firebase from 'firebase'
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//import FbIcon from './facebook.svg';
import { Menu,
         Notification,
         translate,
         userLogin, } from 'react-admin';

import MainAppBar from './layout/MainAppbar'

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

function FbIcon(props) {
  return (
    <SvgIcon viewBox="0 0 120 120" {...props}>
      <path d="M113.377146,0 L6.62285402,0 C2.96477253,0 0,2.96432291 0,6.62285402 L0,113.377146 C0,117.034778 2.96477253,120 6.62285402,120 L64.0955585,120 L64.0955585,73.5298658 L48.45744,73.5298658 L48.45744,55.419305 L64.0955585,55.419305 L64.0955585,42.0634409 C64.0955585,26.5638044 73.5622382,18.1240492 87.388851,18.1240492 C94.011705,18.1240492 99.7043021,18.6172788 101.362938,18.837591 L101.362938,35.0354823 L91.7735131,35.0399784 C84.2536737,35.0399784 82.7978149,38.6130823 82.7978149,43.8565123 L82.7978149,55.419305 L100.731676,55.419305 L98.3963671,73.5298658 L82.7978149,73.5298658 L82.7978149,120 L113.377146,120 C117.034778,120 120,117.034778 120,113.377146 L120,6.62285402 C120,2.96432291 117.034778,0 113.377146,0" id="Imported-Layers-Copy" fill="#FFFFFF"></path>
    </SvgIcon>
  );
}

function checkLogin(self){
  firebase.auth().getRedirectResult().then(function(result) {
    self.setState({isLoading:false})
    if (result.credential) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //var token = result.credential.accessToken;
      document.location.assign("/");
    } else {
      console.log('no credential')
    }
  }).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    //var errorMessage = error.message;
    // The email of the user's account used.
    //var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    //var credential = error.credential;
    // [START_EXCLUDE]
    if (errorCode === 'auth/account-exists-with-different-credential') {
      alert('You have already signed up with a different way for that email.');
      // If you are using multiple auth providers on your app you should handle linking
      // the user's accounts here.
    } else {
      console.error(error);
      alert(error.message);
    }
    // [END_EXCLUDE]
  });
}

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/768x1280/?boxing,training)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,' +
          '"Segoe UI",Lato,"Helvetica Neue",Arial,sans-serif',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    fbicon:{
      marginRight: '1em'
    }
});

class Login extends Component {
    constructor(props){
      super(props)
      this.state = {isLoading:false, isForget:false}
    }

    componentWillMount() {
      this.setState({isLoading:true})
      checkLogin(this)
    }

    renderTitleStyle(){
      return {objectFit:'scale-down', height:32, width:'100%'}
    }

    login = auth =>
        this.props.userLogin(
            auth,
            this.props.location.state
                ? this.props.location.state.nextPathname
                : '/'
        );

    handleChange = name => event => {
        this.setState({[name]: event.target.checked });
        //console.log('event:' + event.target.checked)
      };

    handleForget = () => {
      //console.log('props:' + this.props.recordLiveValues?this.props.recordLiveValues.username:'null')
      if(!this.state.isForget){
        this.setState({isForget:true});
      }else if(this.props.recordLiveValues && this.props.recordLiveValues.resetPasswordByEmail){
        console.log('Sending email...')
        this.setState({isLoading:true})
        firebase.auth().sendPasswordResetEmail(this.props.recordLiveValues.resetPasswordByEmail)
        .then(function() {
        // Email sent.
          console.log('email sent')
        }).catch(function(error) {
        // An error happened.
          console.log('error happened:' + error)
          return error
        });
      }

      console.log('isForget:' + this.state.isForget)
    }

    render() {
        const { classes, handleSubmit, isLoading, translate } = this.props;
        return (
            <div className={classes.main}>
              <MainAppBar />
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <LockIcon />
                    </div>
                    <form onSubmit={handleSubmit(this.login)}>
                      { (this.state.noFbChk || this.state.newUser) &&
                        !this.state.isForget
                        &&(
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    ref={node => {this.input = node;}}
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={translate('pos.email')}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                      )}
                      { this.state.isForget &&
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="resetPasswordByEmail"
                                    component={renderInput}
                                    label={translate('pos.email')}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                      }
                      {this.state.newUser &&(
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="displayName"
                                    component={renderInput}
                                    label={translate('pos.displayName')}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                      )}
                      {!this.state.isForget &&
                        <CardActions className={classes.actions}>
                            <Button
                                variant="raised"
                                type="submit"
                                color="primary"
                                disabled={this.state.isLoading}
                                className={classes.button}
                                fullWidth
                            >
                              {!this.state.noFbChk && !this.state.newUser && (
                                <FbIcon className={classes.fbicon} />
                                )
                              }
                              {this.state.isLoading && (
                                  <CircularProgress size={25} thickness={2} />
                              )}
                              {!this.state.noFbChk && !this.state.newUser && translate('pos.facebook')}
                              {this.state.noFbChk && !this.state.newUser && translate('pos.login')}
                              {this.state.newUser && translate('pos.register')}
                            </Button>
                            {this.state.noFbChk &&
                              <Button
                                  variant="raised"
                                  color="primary"
                                  disabled={this.state.isLoading}
                                  className={classes.button}
                                  fullWidth
                                  onClick={this.handleForget}
                              >
                                 {translate('pos.iforget')}
                              </Button>
                            }
                        </CardActions>
                      }
                      {this.state.isForget &&
                        <CardActions className={classes.actions}>
                          <Button
                              variant="raised"
                              color="primary"
                              disabled={this.state.isLoading}
                              className={classes.button}
                              fullWidth
                              onClick={this.handleForget}
                          >
                             {translate('pos.sendRecoveryEmail')}
                          </Button>
                        </CardActions>
                      }
                    </form>
                    <div className={classes.avatar}>
                      <FormGroup row>
                        <FormControlLabel
                          label={translate('pos.newUser')}
                          control={
                           <Checkbox
                            disabled={isLoading || this.state.noFbChk || this.state.isForget}
                            checked={this.state.newUser}
                            onChange={this.handleChange('newUser')}
                            value="noFbChk"
                            />} />
                        <FormControlLabel
                          label={translate('pos.noFbChk')}
                          control={
                           <Checkbox
                            disabled={isLoading || this.state.newUser || this.state.isForget}
                            checked={this.state.noFbChk}
                            onChange={this.handleChange('noFbChk')}
                            value="noFbChk"
                            />} />
                      </FormGroup>
                      <Notification />
                    </div>
                </Card>
            </div>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  recordLiveValues: state.form['signIn'].values
});

const enhance = compose(
    translate,
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username) {
                errors.username = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            if (!values.displayName) {
                errors.displayName = translate('ra.validation.required');
            }
            return errors;
        },
    }),
    connect(
        mapStateToProps,
        { userLogin }
    ),
    withStyles(styles),
);

export default enhance(Login);
