import React from 'react';
import Card from '@material-ui/core/Card';
import TimeIcon from '@material-ui/icons/AccessTime';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { translate } from 'react-admin';
import moment from 'moment-timezone'

import CardIcon from './CardIcon';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
      padding: '16px 0',
      overflow: 'inherit',
      textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    noshow:{
      display: 'none'
    }
};

const ClassSum = ({ workout_times, gct_times, pt_times, yoga_times, rpt_times, isLoading, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={TimeIcon} bgColor="#FF3E24" href="/#/my-profile" />
        <Card className={classes.card}>
          <Typography className={classes.noshow} color="textSecondary">
              {translate('pos.dashboard.welcome.workout')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.noshow}
          >
              {isLoading?<LinearProgress />:workout_times+translate('pos.dashboard.welcome.times')}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.welcome.gct')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:gct_times+translate('pos.dashboard.welcome.classes')}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.welcome.pt')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:pt_times+translate('pos.dashboard.welcome.classes')}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.welcome.yoga')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:yoga_times+translate('pos.dashboard.welcome.classes')}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.welcome.rpt')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:rpt_times+translate('pos.dashboard.welcome.classes')}
          </Typography>
        </Card>
    </div>
);

export default translate(withStyles(styles)(ClassSum));
