import TrainerIcon from '@material-ui/icons/Collections';
import TrainerList from './TrainerList';
import TrainerEdit from './TrainerEdit';
import TrainerShow from './TrainerShow';
import TrainerCreate from './TrainerCreate';

export default{
  list: TrainerList,
  create: TrainerCreate,
  edit: TrainerEdit,
  show: TrainerShow,
  icon: TrainerIcon,
}
