import React from 'react';
import {
    Create,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';

export const styles = {
    stock: { width: '5em' },
    price: { width: '5em' },
    width: { width: '5em' },
    widthFormGroup: { display: 'inline-block' },
    height: { width: '5em' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const TrainerCreate = ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm>
          <FormTab label="resources.trainers.tabs.image">
              <ImageInput source="avatar" label="Avatar" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
          </FormTab>
          <FormTab label="resources.trainers.tabs.details" path="details">
            <TextInput source="trainer_name" validate={required()} />
            <TextInput source="trainer_title" validate={required()} />
            <TextInput source="trainer_slogan"  />
          </FormTab>
          <FormTab
              label="resources.trainers.tabs.social_link"
              path="ig_html"
          >
            <TextInput source="ig_link"  />
            <TextInput source="fb_link"  />
            <TextInput source="li_link"  />
          </FormTab>
          <FormTab
              label="resources.trainers.tabs.description"
              path="description"
          >
            <RichTextInput
              toolbar={[
                ['bold', 'italic', 'underline', 'link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'align': [] }], ]}
              source="description" />
          </FormTab>
      </TabbedForm>
    </Create>
);

export default withStyles(styles)(TrainerCreate);
