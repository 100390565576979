import React from 'react';
import {
    Edit,
    TextInput,
    required,
    SimpleForm
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { styles as createStyles } from './TermCreate';

const styles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const TermEdit = ({ classes, ...props }) => (
    <Edit {...props} >
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <TextInput source="content" validate={required()} />          
        </SimpleForm>
    </Edit>
);

export default withStyles(styles)(TermEdit);
