import React from 'react';
import {
    translate,
    AutocompleteInput,
    TextInput,
    DateInput,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import EventSelectInput from '../events/EventSelectInput'

const editStyles = {
    root: { alignItems: 'flex-start' },
};

const OrderEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput source="event_id" reference="t_events">
          <EventSelectInput optionText="class_name" />
        </ReferenceInput>
        <TextInput source="client_id" style={{display:'none'}}/>
      </SimpleForm>
    </Edit>
);

export default withStyles(editStyles)(OrderEdit);
