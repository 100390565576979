import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  WORKOUT: '自主訓練',
  user:{
    action:{
      reserve: "預約",
    }
  },
  mins: '分鐘',
  GCT: '重訓小團課',
  YOGA: '瑜珈',
  PT: '一對一',
  RPT: '場租教學',
  query: {
    s_disagree: '非常不同意',
    disagree: '不同意',
    neutral: '沒特別感覺',
    agree: '同意',
    s_agree: '非常同意',
    sleep_sts: '我覺得睡眠品質很好',
    digest_sts: '我幾乎沒有便祕或是腹瀉症狀',
    exe_sts: '我有參考飲食建議進行控制',
    satiety_sts: '我週間常常感到飢餓',
    training_sts: '我可以接受訓練後的痠痛感',
    energy_sts: '我練習過程覺得精力充沛',
  },
  pos: {
      agreeToPolicy: '防疫期間特別條款',
      antiVirusPolicy: '本人及學員 21 天無出國紀錄、無發燒、乾咳等感冒症狀，並且未與任何隔離病患同住，進出場館確實消毒雙手、禁止飲食、避免交談。',
      agree: '同意並遵守場館衛生規定',
      noFbChk: 'E-mail 登入',
      newUser: 'E-mail 註冊',
      email: 'E-mail',
      displayName: 'Your Name',
      register: '註冊',
      login: '登入',
      facebook: 'Facebook Login',
      search: '搜尋',
      configuration: '設定',
      language: '語言',
      product:'商品',
      iforget: '忘記密碼?',
      sendRecoveryEmail: '寄送密碼回復連結至信箱',
      emailSent: '信件已送出',
      emailError: '信件無法送出',
      checkout: '線上結帳',
      amount: '數量',
      total: '總金額',
      confirm: '確認數量',
      rcvInfo: '運送資訊',
      rcvName:'收件人姓名',
      rcvPhone:'收件人電話',
      rcvZIP:'郵遞區號',
      rcvAddr:'收件地址',
      rcvHint: '我確認運送資訊無誤要線上付款',
      country:'送達國家',
      payOnline: '線上付款',
      payOnthego: '付現',
      payOnthegoHint: '請現場付費',
      payByTransfer: '銀行匯款',
      price: '價格',
      nowNow: '先不用',
      needLogin: '請先登入系統',
      valueAdded: '加值',
      dashboard: {
          name: '資訊看板',
          monthly_usage: '月使用量',
          my_points: '剩餘點數',
          mins_left: '可用分鐘',
          free_gct_left: '贈送循環課',
          loading: '讀取中',
          due_date: '使用期限',
          new_orders: '新訂單',
          new_customers: '新學員',
          now_users: '場內人數',
          this_resv: '本月預約',
          last_resv: '上月預約',
          last2_resv: '前月預約',
          now_using: '本日 19:40-20:50 僅供瑜珈課使用',
          order: {
              items:
                  'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
          },
          welcome: {
              title: '歡迎',
              sub_title: ' 來',
              id20191209zxfs: '忠孝復興店',
              id20190722ngs: '南港店',
              id20210401kys: '昆陽店',
              select_course: '如欲上課請勾選後再進入場館',
              times: '次',
              classes: '堂',
              workout: '自主訓練',
              gpt: '重訓小學堂',
              gct: '循環訓練',
              yoga: '瑜珈',
              pt: '一對一',
              ptv2: '一對二',
              rpt: '場租教學',
              no_access: 'NO ACCESS!',
              door_button: 'Open Leap Door',
              door_red_button: '休息室',
              door_1f_button: '開啟一樓',
              door_2f_button_in: '進入場館',
              door_2f_button_out: '離開場館',
              door_ngs_button_in: '進入場館',
              door_ngs_button_out: '離開場館',
              demo_button: '請聯絡力坊',
              door_openning: '開啟中...',
              door_granted: '准許進入, 歡迎光臨',
              door_earlylate: '早或晚於 10 分鐘進入',
              door_nobooking: '沒找到您的訂閱紀錄',
              door_error: '門禁錯誤, 請私訊力坊臉書或官方 Line',
              server_error: '後台錯誤, 請私訊力坊臉書或官方 Line',
              booking_title: '請在此預約',
              already_checked: '您已進入場館',
              already_left: '您已離開場館',
              left_no_deduct: '不需扣點',
              left_point_deduct: '點數已扣除',
              fully_occupied: '人數已滿, 請稍後進場',
              welcome_checkin_deduct: '歡迎光臨! 點數已扣除',
              welcome_checkin: '歡迎光臨力坊!',
              no_balance: '點數不足, 請私訊力坊臉書或官方 Line'
          },
      },
  },
  resources: {
      customers: {
          name: '學員 |||| 學員們',
          fields: {
            checked: '在館內',
            balance: '點數餘額',
            groups: 'Segments',
            last_unlock_datetime: '最後進出場時間',
            line_name: 'LINE名稱',
            free_gct: '贈送循環課',
            name: '姓名',
            acc_mins: '已使用分鐘(已扣點)',
            gctChecked: '循環',
            yogaChecked: '瑜珈',
            gptChecked: '學堂',
            ptChecked: '一對一',
            ptv2Checked: '一對二',
            rptChecked: '場租教學'
          },
          tabs: {
            usage: '進場紀錄',
            usage_edit: '編輯紀錄',
            identity: '個人資料',
            address: '聯絡地址',
            orders: '訂單',
            reviews: 'Reviews',
            class: '課程紀錄',
            status: '點數狀態',
            payment: '付款紀錄'
          },
          page: {
              delete: 'Delete Customer',
          },
      },
      orders: {
          name: '購買紀錄',
          fields: {
              basket: {
                  delivery: 'Delivery',
                  reference: 'Reference',
                  quantity: 'Quantity',
                  sum: 'Sum',
                  tax_rate: 'Tax Rate',
                  total: 'Total',
                  unit_price: 'Unit Price',
              },
              customer_id: 'Customer',
              date_gte: 'Passed Since',
              date_lte: 'Passed Before',
              total_gte: 'Min amount',
              status: 'Status',
              returned: 'Returned',
          },
      },
      products: {
          name: '課程資訊',
          fields: {
              category_id: 'Category',
              height_gte: 'Min height',
              height_lte: 'Max height',
              height: 'Height',
              image: 'Image',
              price: 'Price',
              reference: 'Reference',
              stock_lte: 'Low Stock',
              stock: 'Stock',
              thumbnail: 'Thumbnail',
              width_gte: 'Min width',
              width_lte: 'Max width',
              width: 'Width',
          },
          tabs: {
              image: '頭像',
              details: '課程資訊',
              description: '文字描述',
              reviews: 'Reviews',
              ig_html: 'IG HTML',
          },
      },
      trainers: {
          name: '尋找教練',
          summary: '預約總稱',
          seat_limit: '人數上限',
          fields: {
              category_id: 'Category',
              height_gte: 'Min height',
              height_lte: 'Max height',
              height: 'Height',
              image: 'Image',
              price: 'Price',
              reference: 'Reference',
              stock_lte: 'Low Stock',
              stock: 'Stock',
              thumbnail: 'Thumbnail',
              width_gte: 'Min width',
              width_lte: 'Max width',
              width: 'Width',
          },
          tabs: {
              image: '頭像',
              details: '課程資訊',
              description: '詳細描述',
              social_link: '社群連結',
              reviews: 'Reviews',
              ig_html: 'IG HTML',
          },
      },
      trackings: {
          name: '全方位紀錄',
          fields: {
              createdAt: '日期',
              bw: '體重',
              sleep_sts: '睡眠品質',
              digest_sts: '消化狀態',
              exe_sts: '飲食執行率',
              satiety_sts: '飽足感',
              training_sts: '訓練完成度',
              energy_sts: '訓練精神',
              comments_nutri: '給營養師的問題',
              comments_training: '給教練的問題',
          },
          tabs: {
              images: '四面照',
              details: '本次紀錄',
          },
      },
      plans: {
          name: '購買點數',
          gift: '購買商品',
          summary: '付款狀態',
          payDatetime: '結帳時間',
          amt: '金額',
          tabs: {
              cover: '封面',
              details: '細節',
              title: '名稱',
              subheader: '副標題',
              description: '描述',
          },
      },
      t_events: {
        name: '開課給學生',
        fields: {
          start__datetime: '上課時間',
          end__datetime: '下課時間',
          capacity: '人數上限',
          class_name: '課程名稱',
          trainer_name: '教練名稱',
          booked: '已預約',
        }

      },

      events: {
          name: '場租紀錄',
          fields: {
              category_id: 'Category',
              height_gte: 'Min height',
              height_lte: 'Max height',
              height: 'Height',
              image: 'Image',
              price: 'Price',
              reference: 'Reference',
              stock_lte: 'Low Stock',
              stock: 'Stock',
              thumbnail: 'Thumbnail',
              width_gte: 'Min width',
              width_lte: 'Max width',
              width: 'Width',
              class_list: '課程列表',
          },
          tabs: {
              image: 'Image',
              details: 'Details',
              description: 'Description',
              reviews: 'Reviews',
          },
      },
      myevents: {
        name: 'My Event |||| My Events',
      },
      terms: {
        name: '使用者條款',
      },
      profile:{
        name: '個人檔案',
        datetime_left: '離場時間',
        mins_used: '使用分鐘',
        type_used: '用途',
      },
      bookings:{
        name: '預定課程',
        manage: 'Manage Booking',
        booked: '已預約',
        undefined: '預約錯誤',
        noshow: '無故缺席',
        cancelled: '已取消',
        used: '已使用',
        fields: {
            start__datetime: '開始時間',
            class_name: '課程名稱',
            trainer_name: '教練名稱',
            status: '狀態',
            event_id: '開始時間',
        },
      }
      ,
      gyms:{
        name: '健身房',
        location: '場館位置',
      }
  },
};
