import React from 'react';
import { Route } from 'react-router-dom';
import Signup from './Signup';
import Profile from './profile';

export default [
    <Route exact path="/my-profile" component={Profile.show} />,
    <Route exact path="/my-profile/mydata" component={Profile.edit} />,
    <Route exact path="/signup" component={Signup} />,
];
