import React from 'react';
import {
    List,
    Datagrid,
    CreateButton,
    EditButton,
    CardActions,
    TextField,
} from 'react-admin';

const TermActions = ({
    basePath,
}) => (
    <CardActions>
      <CreateButton basePath={basePath} />
    </CardActions>
);

const TermList = ({permissions, ...props}) => (
  <div>
    <List
      {...props}
      sort={{ field: 'title', order: 'ASC' }}
      perPage={20}
      exporter={false}
      bulkActionButtons={false}
      actions={permissions?<TermActions {...props}/>:null}>
        <Datagrid>
          <TextField source="title" />
          <TextField source="content" />
          {permissions?<EditButton />:null}
        </Datagrid>
    </List>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfiGoJ5Eta8aZMf0OXzxeA-p8L3uJrG2fAZfyj7VAYW65SqCA/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe>
  </div>
);

export default TermList;
