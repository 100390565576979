import TermIcon from '@material-ui/icons/Collections';
import TermList from './TermList';
import TermEdit from './TermEdit';
import TermCreate from './TermCreate';

export default{
  list: TermList,
  create: TermCreate,
  edit: TermEdit,
  icon: TermIcon,
}
