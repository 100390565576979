import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import DataProvider from '../dataProviders'
import moment from 'moment-timezone'
import CardIcon from './CardIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GET_ONE } from 'react-admin';

const styles = theme => ({
    main: {
        flex: '1',
        marginLeft: '0em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
});

const dataProvider = DataProvider();

const NowUsers = ({ isLoading, translate, classes, currentLoc }) => {
  const [amount, setAmount] = useState(0);
  const [hint, setHint] = useState('');
  useEffect(() => {
    dataProvider(GET_ONE, 'gyms', {id:currentLoc} )
    .then(response => {
      if(response.data){
        //console.log('userPool:' + Object.keys(response.data.userPool).length)
        if(response.data.userPool){
          // sum amount of user
          var amount = 0
          for (var prop in response.data.userPool){
            console.log('userPool[prop]: '+ response.data.userPool[prop] )
            amount = response.data.userPool[prop] + amount
          }
          setAmount( response.data.userPool?amount:0)
        }else{
          console.log('userPool is not found')
        }

        if(response.data.userHint){
          const nowDay = moment(new Date()).tz("Asia/Taipei").format('ddd')
          setHint(response.data.userHint?response.data.userHint[nowDay]:"")
        }else{
          console.log('userHint is not found:')
        }
      }
    }).catch( err => {
      console.log('get gyms error: ' + err)
    })
  })

  return(
    <div className={classes.main}>
      <CardIcon Icon={CustomerIcon} bgColor="#4caf50" href="https://line.me/R/ti/p/%40qpx8269n"/>
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
            {translate('pos.dashboard.now_users')}
        </Typography>
        <Typography
            variant="headline"
            component="h2"
            className={classes.value}
        >
          {isLoading?<LinearProgress />:amount}
        </Typography>
        <Divider />
        <Typography className={classes.title} color="textSecondary">
            {hint}
        </Typography>
      </Card>
    </div>
  );
}

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(NowUsers);
