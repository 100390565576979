// in src/comments.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import { DateField, EditButton, translate, NumberField, BooleanField} from 'react-admin';

import AvatarField from './AvatarField';
import ColoredNumberField from './ColoredNumberField';
import SegmentsField from './SegmentsField';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    card_checked: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
        backgroundColor: '#f93822',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
});

const MobileGrid = withStyles(listStyles)(
    translate(({ classes, ids, data, basePath, translate }) => (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} className={data[id].checked?classes.card_checked:classes.card}>
                    <CardHeader
                        title={
                            <div >
                                {`${data[id].username}`}
                                {`${data[id].line_name?data[id].line_name:''}`}
                            </div>
                        }
                        subheader={
                          data[id].gctChecked?translate(
                              'pos.dashboard.welcome.gct'
                          ):data[id].rptChecked?translate(
                              'pos.dashboard.welcome.rpt'
                          ):data[id].ptChecked?translate(
                              'pos.dashboard.welcome.pt'
                          ):data[id].gptChecked?translate(
                              'pos.dashboard.welcome.gpt'
                          ):data[id].ptv2Checked?translate(
                              'pos.dashboard.welcome.ptv2'
                          ):translate(
                              'pos.dashboard.welcome.workout'
                          )}
                        avatar={<AvatarField record={data[id]} size="45" />}
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                          <BooleanField source="gctChecked" />
                          <BooleanField source="yogaChecked" />
                          <BooleanField source="gptChecked" />
                          <BooleanField source="ptChecked" />
                          <BooleanField source="ptv2Checked" />
                          <BooleanField source="rptChecked" />
                        </div>
                        <div>
                            {translate(
                                'resources.customers.fields.last_unlock_datetime'
                            )}&nbsp;
                            <DateField
                              record={data[id]}
                              source="last_unlock_datetime"
                              type="datetime-local"
                              showTime
                              options={ {weekday: 'long', month: 'long',
                              day: 'numeric', hour: 'numeric', minute: '2-digit'} }
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.customers.fields.balance',
                                parseInt(data[id].nb_commands, 10) || 1
                            )}&nbsp;:&nbsp;<NumberField
                                record={data[id]}
                                source="balance"
                                label="resources.customers.fields.balance"
                                className={classes.nb_commands}
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.customers.fields.acc_mins'
                            )}&nbsp; :{' '}
                            <ColoredNumberField
                                record={data[id]}
                                source="acc_mins"
                            />
                        </div>

                    </CardContent>
                    <CardActions>
                      <EditButton
                          resource="customers"
                          basePath={basePath}
                          record={data[id]}
                      />
                    </CardActions>
                    {data[id].groups &&
                        data[id].groups.length > 0 && (
                            <CardContent className={classes.cardContent}>
                                <SegmentsField record={data[id]} />
                            </CardContent>
                        )}
                </Card>
            ))}
        </div>
    ))
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
