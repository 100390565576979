import BookingIcon from '@material-ui/icons/AttachMoney';

import BookingList from './BookingList';
import BookingEdit from './BookingEdit';
import BookingCreate from './BookingCreate';

export default {
    list: BookingList,
    edit: BookingEdit,
    icon: BookingIcon,
    create: BookingCreate,
};
