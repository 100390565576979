import React from 'react';
import {
    Create,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    FormDataConsumer,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';

export const styles = {
    stock: { width: '5em' },
    price: { width: '5em' },
    width: { width: '5em' },
    widthFormGroup: { display: 'inline-block' },
    height: { width: '5em' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const PlanCreate = ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm>
          <FormTab label="resources.plans.tabs.cover">
              <ImageInput source="cover" label="COVER" accept="image/*">
                <ImageField source="src" title="cover_title" />
              </ImageInput>
          </FormTab>
          <FormTab label="resources.plans.tabs.details" path="details">
            <TextInput label="resources.plans.tabs.title" source="title" validate={required()} />
            <TextInput label="resources.plans.tabs.subheader" source="subheader" />
            <SelectInput
                source="unit"
                validate={required()}
                defaultValue={'30'}
                choices={[
                  { id: 'TWD', name: 'TWD' },
                  { id: 'USD', name: 'USD' },
                  { id: 'points', name: 'points' },
                  { id: 'others', name: 'others' },
                ]}
            />
            <FormDataConsumer>
               {({ formData, ...rest }) => formData.unit &&
                 <NumberInput source="price" validate={required()} />
               }
            </FormDataConsumer>
            <NumberInput source="point" />
            <SelectInput
                source="period"
                defaultValue={'30'}
                choices={[
                  { id: '30', name: '30' },
                  { id: '90', name: '90' },
                  { id: '180', name: '180' },
                  { id: '365', name: '365' },
                ]}
            />
            <NumberInput source="free_gct"  />
          </FormTab>
          <FormTab
              label="resources.plans.tabs.description"
              path="description" >
            <RichTextInput
              toolbar={[
                ['bold', 'italic', 'underline', 'link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'align': [] }], ]}
              source="description" />
          </FormTab>
      </TabbedForm>
    </Create>
);

export default withStyles(styles)(PlanCreate);
