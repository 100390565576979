import React from 'react';
import {
    List,
    CreateButton,
    CardActions,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    Filter,
    DateInput,
    EditButton,
    SearchInput,
    translate,
} from 'react-admin';

import OrderGrid from './OrderGrid';

const OrderActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
      <EditButton basePath={basePath} />
    </CardActions>
);

const OrderFilter = props => (
    <Filter {...props}>
        <SearchInput source="rcvName" alwaysOn />
        <SearchInput source="newebMsg" alwaysOn />
    </Filter>
);

const OrderList = ({permissions, translate, ...props}) => {
  return(
    <List {...props}
      filters={<OrderFilter />}
      bulkActions={permissions?true:false}
      perPage={30}
      sort={{ field: 'payDatetime', order: "ASC" }}
      actions={permissions?<OrderActions {...props}/>:null}>
      <Datagrid>
      {permissions?(
        <ReferenceField
          source="buyerId" reference="customers" linkType="show">
           <TextField source="username" />
        </ReferenceField>
      ):('')}
      {permissions?(
        <TextField label={translate('pos.rcvName')} source="rcvName"  />
      ):('')}
      {permissions?(
        <TextField label={translate('pos.rcvAddr')} source="rcvAddr"  />
      ):('')}
        <ReferenceField label={translate('pos.product')}
        source="productId" reference="plans" linkType="show">
           <TextField source="title" />
        </ReferenceField>
        <TextField label={translate('resources.plans.summary')} source="newebMsg" />
        <TextField label={translate('resources.plans.payDatetime')} source="payDatetime"  />
        <TextField label={translate('pos.total')} source="rcvTotal"  />
        {permissions?(<EditButton  />):('')}
      </Datagrid>
    </List>
)};

export default translate(OrderList);
