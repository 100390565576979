import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import DataProvider from '../dataProviders'
import moment from 'moment-timezone'
import CardIcon from './CardIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GET_ONE, GET_MANY } from 'react-admin';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const styles = theme => ({
    main: {
        flex: '1',
        marginLeft: '0em',
        marginTop: 20,
        padding: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
});

const dataProvider = DataProvider();

const NowNews = ({ isLoading, translate, classes, currentLoc, news }) => {
  const [amount, setAmount] = useState(0);
  const [run, setRun] = useState('');

  return(
    <div className={classes.main}>
      <Carousel showThumbs={false} autoPlay >
        {news?(
          news.map(
            item =>(
              <div>
                  <img src={item.src} />

                  <p className="legend" hidden={item.title?false:true}>{item.title?item.title:''}</p>
              </div>
            )
          )
        ):('')
        }
      </Carousel>
    </div>
  );
}

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(NowNews);
