import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
import ParkingIcon from '@material-ui/icons/LocalParking';
import TimeIcon from '@material-ui/icons/AccessTime';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { translate } from 'react-admin';
import moment from 'moment-timezone'
import LinearProgress from '@material-ui/core/LinearProgress';

import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
      padding: '16px 0',
      overflow: 'inherit',
      textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    noshow:{
      display: 'none'
    }
};

const MyPoints = ({ value, mins_left, free_gct, due, translate, classes, isLoading }) => (
    <div className={classes.main}>
        <CardIcon Icon={DollarIcon} bgColor="#FF3E24"
          href="#/plans" />
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.my_points')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:value}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.mins_left')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:mins_left}
          </Typography>
          <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.free_gct_left')}
          </Typography>
          <Typography
          variant="subtitle1"
          component="h4"
              className={classes.value}
          >
              {isLoading?<LinearProgress />:free_gct}
          </Typography>

          <Typography className={classes.noshow} color="textSecondary">
              {translate('pos.dashboard.due_date')}
              {isLoading?<LinearProgress />:moment(due).tz("Asia/Taipei").format("YYYY-MM-DD")}
          </Typography>
        </Card>
    </div>
);

export default translate(withStyles(styles)(MyPoints));
