import PlanIcon from '@material-ui/icons/Collections';
import PlanList from './PlanList';
import PlanEdit from './PlanEdit';
import PlanShow from './PlanShow';
import PlanCreate from './PlanCreate';

export default{
  list: PlanList,
  create: PlanCreate,
  edit: PlanEdit,
  show: PlanShow,
  icon: PlanIcon,
}
