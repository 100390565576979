import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import DataProvider from '../dataProviders'
import moment from 'moment-timezone'
import CardIcon from './CardIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GET_LIST } from 'react-admin';

const styles = theme => ({
    main: {
        flex: '1',
        marginLeft: '0em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
});

const dataProvider = DataProvider();

const MyResv = ({ isLoading, translate, classes }) => {
  const [amount, setAmount] = useState(0);
  const [amount1, setAmount1] = useState(0);
  const [amount2, setAmount2] = useState(0);
  const [hint, setHint] = useState('');
  useEffect(() => {
    dataProvider(GET_LIST, 'myreservations', {pagination:{page:1,perPage:125}} )
    .then(response => {
      var now = new Date()
      var thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      var lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      var last2Month = new Date(now.getFullYear(), now.getMonth() - 2, 1);
      //console.log('.getTime()' + thisMonth.getTime())
      if(response.data){
        var amount = 0, amount1 = 0, amount2 = 0
        response.data.map(value=>{
          var resvDatetime = new Date(value.resTimestamp)
          //console.log('resvDatetime'+ resvDatetime.toLocaleString())
          if(value.resTimestamp > thisMonth.getTime()){
            amount++
          }else if(value.resTimestamp <= thisMonth.getTime() && value.resTimestamp > lastMonth){
            amount1++
          }else if(value.resTimestamp <= lastMonth.getTime() && value.resTimestamp > last2Month){
            amount2++
          }
        })
        setAmount(amount?amount:0)
        setAmount1(amount1?amount1:0)
        setAmount2(amount2?amount2:0)
      }else{
        console.log('resTimestamp is not found')
      }
    }).catch( err => {
      console.log('get resTimestamp error: ' + err)
    })
  })

  return(
    <div className={classes.main}>
      <CardIcon Icon={CustomerIcon} bgColor="#4caf50" href="https://line.me/R/ti/p/%40qpx8269n"/>
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
            {translate('pos.dashboard.this_resv')}
        </Typography>
        <Typography
            variant="subtitle1"
            component="h4"
            className={classes.value}
        >
          {isLoading?<LinearProgress />:amount}堂
        </Typography>
        <Typography className={classes.title} color="textSecondary">
            {translate('pos.dashboard.last_resv')}
        </Typography>
        <Typography
          variant="subtitle1"
          component="h4"
            className={classes.value}
        >
          {isLoading?<LinearProgress />:amount1}堂
        </Typography>
        <Typography className={classes.title} color="textSecondary">
            {translate('pos.dashboard.last2_resv')}
        </Typography>
        <Typography
          variant="subtitle1"
          component="h4"
            className={classes.value}
        >
          {isLoading?<LinearProgress />:amount2}堂
        </Typography>
      </Card>
    </div>
  );
}

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(MyResv);
