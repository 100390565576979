import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import classnames from 'classnames';
import { showNotification, translate } from 'react-admin';
import unlockFunc from './unlockFunc'

const styles = {
    button: {
        position: 'relative',
    },
    iconPaddingStyle: {
        marginRight: '0.5em',
    },
};

const sanitizeRestProps = ({
    basePath,
    className,
    classes,
    saving,
    unlock,
    label,
    invalid,
    variant,
    translate,
    handleSubmit,
    handleSubmitWithRedirect,
    submitOnEnter,
    redirect,
    locale,
    showNotification,
    refresh,
    gptChecked,
    gctChecked,
    ptChecked,
    ptv2Checked,
    yogaChecked,
    rptChecked,
    ...rest
}) => rest;

export class UnlockButton extends Component {
    handleClick = e => {
        const {
            handleSubmitWithRedirect,
            invalid,
            redirect,
            saving,
            unlock,
            showNotification,
            refresh,
            gptChecked,
            gctChecked,
            ptChecked,
            ptv2Checked,
            yogaChecked,
            rptChecked,
        } = this.props;
        showNotification('pos.dashboard.welcome.door_openning','info',{'autoHideDuration':1000});
        unlockFunc(unlock, showNotification, refresh,
          gptChecked,
          gctChecked,
          ptChecked,
          ptv2Checked,
          yogaChecked,
          rptChecked,)
        //console.log('gptChecked,  gctChecked,  ptChecked,  yogaChecked ' + gptChecked
        //+ gctChecked + ptChecked + yogaChecked)
        if (saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            if (invalid) {
                showNotification('pos.dashboard.welcome.door_error', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault();
            }
            handleSubmitWithRedirect(redirect)();
        }
    };

    render() {
        const {
            className,
            classes = {},
            invalid,
            label = 'ra.action.save',
            pristine,
            redirect,
            saving,
            unlock,
            gptChecked,
            gctChecked,
            ptChecked,
            yogaChecked,
            rptChecked,
            submitOnEnter,
            translate,
            variant = 'raised',
            ...rest
        } = this.props;

        const type = submitOnEnter ? 'submit' : 'button';
        return (
            <Button
                className={classnames(classes.button, className)}
                variant={variant}
                type={type}
                onClick={this.handleClick}
                color={saving ? 'default' : 'primary'}
                {...sanitizeRestProps(rest)}
            >
                {saving && saving.redirect === redirect ? (
                    <CircularProgress
                        size={25}
                        thickness={2}
                        className={classes.iconPaddingStyle}
                    />
                ) : (
                    <LockOpenIcon className={classes.iconPaddingStyle} />
                )}
                {label && translate(label, { _: label })}
            </Button>
        );
    }
}

UnlockButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    unlock: PropTypes.string,
    showNotification: PropTypes.func,
    refresh: PropTypes.func,
    gptChecked: PropTypes.bool,
    gctChecked: PropTypes.bool,
    ptChecked: PropTypes.bool,
    yogaChecked: PropTypes.bool,
    rptChecked: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['raised', 'flat', 'fab']),
};

UnlockButton.defaultProps = {
    handleSubmitWithRedirect: () => () => {},
};

const enhance = compose(
    translate,
    connect(
        undefined,
        { showNotification }
    ),
    withStyles(styles)
);

export default enhance(UnlockButton);
