import React from 'react';
import {
    List,
    CreateButton,
    CardActions,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    Filter,
    DateInput,
    EditButton,
} from 'react-admin';

import ProductGrid from './ProductGrid';
import ProductShow from './ProductShow';

const ProductActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
      <CreateButton basePath={basePath} />
    </CardActions>
);

const ProductList = ({permissions, ...props}) => (
  <List
    {...props}
    perPage={20}
   actions={permissions?<ProductActions {...props}/>:null}>
     <ProductGrid />
  </List>
);

/*
<Datagrid rowClick="expand" expand={<ProductShow />}>
  <TextField source="class_name" />
  <ReferenceField source="location_id" reference="gyms">
      <TextField source="location" />
  </ReferenceField>
  <EditButton />
</Datagrid>
*/

export default ProductList;
