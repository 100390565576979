import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button:{
    marginRight:"24px"
  }
}

const LocSwitcher = ({switchToZFS, switchToNGS, switchToKYS, classes}) => (
  <Card>
    <CardHeader titleTypographyProps={{style:{fontSize:'1em'}}}
      title={"手動切換位置"}
    />
    <CardContent>
      <Button className={classes.button} variant="contained" onClick={switchToZFS}>忠孝</Button>      
      <Button className={classes.button} variant="contained" onClick={switchToKYS}>昆陽</Button>
      <Button className={classes.button} variant="contained" onClick={switchToNGS}>南港</Button>
      </CardContent>
  </Card>
)

export default translate(withStyles(styles)(LocSwitcher));
