import React from 'react';
import PropTypes from 'prop-types';
import { translate, FunctionField } from 'react-admin';
import get from 'lodash/get';
const TranslateField = translate(( { translate, namespace, ...props } ) => {
    return (
        <FunctionField {...props}
            render={ (record, source) => translate(`${namespace}.${get(record, source)}`) }
        />
    )
});
TranslateField.propTypes = {
    namespace: PropTypes.string
}
TranslateField.defaultProps = {
    namespace: 'dbphrases',
};

export default TranslateField;
