import EventIcon from '@material-ui/icons/AttachMoney';

import EventList from './EventList';
import EventEdit from './EventEdit';
import EventCreate from './EventCreate';

export default {
    list: EventList,
    edit: EventEdit,
    icon: EventIcon,
    create: EventCreate,
};
