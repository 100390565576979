import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { NumberField,
         TextField,
         DateField,
         ReferenceField,
         EditButton,
         RichTextField,
        } from 'react-admin';
import { linkToRecord } from 'ra-core';
import {AppBar, Toolbar , Dialog, Divider, Slide , Button, Card, CardHeader, CardMedia, CardContent} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParking } from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    root: {
      margin: '-0.4em',
      display: 'block',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    gridList: {
        width: '100%',
        margin: 0,
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    cardStyle: {
      width: '100%',
      margin: '0.2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    showlayout:{
      display: 'block',
      margin: '0 0 0 0',
      padding: '0 0 0 0',
      textAlign: 'center',
    },
    flexContainer:{
      width: '100%',
      display: 'flex',
      margin: '0.5em 0.1em 0.5em 0.1em',
      justifyContent: 'center',
    },
    iconStyle:{
      fontSize: '1.5em',
      padding: '0 0.2em 0 0',
      color: 'grey',
      textAlign: 'left',
    },
    rightPadding:{
      padding: '0 1em 0 0',
      color: 'grey',
      fontSize: '1.5em',
      textAlign: 'center',
    },
    class_name:{
      fontSize: '1.5em',
      margin: '0.em 0.1em 0.5em 0.1em',
    },
    bottom_div:{
      position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
      padding: 6,
      backgroundColor: '#efefef',
      textAlign: 'center',
    },
});

const getColsForWidth = width => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 1;
    if (width === 'md') return 2;
    if (width === 'lg') return 3;
    return 3;
};

const getHeightForGrid = width => {
    if (width === 'xs') return 200;
    if (width === 'sm') return 240;
    if (width === 'md') return 360;
    if (width === 'lg') return 480;
    return 480;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const CreateYoutube= ({ youtubeId }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
      />
    </div>
  )
}

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const InfoCol =  ({ record, classes }) => {
  return(
    <div className={classes.flexContainer}>
      <FontAwesomeIcon icon={faParking} className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.price}` : ''}</span>
      <TimeIcon className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.length}` : ''}</span>
      <PersonIcon className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.seat_floor}~${record.seat_ceil}` : ''}</span>
    </div>
  )
}

const CreateBookingButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/bookings/create',
            state: { record: { class_id: record.id } },
        }}
    >
        Booking
    </Button>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoadedGridList({ classes, ids, data, basePath, width }){
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return(
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {ids.map(id => (
                <GridListTile key={id} style={{ height: 'auto', textDecoration: 'none' }}
                  component={Link}
                  to={`${linkToRecord(basePath, data[id].id)}/show`}>
                  <Card className={classes.cardStyle}>
                    <CardContent>
                      <Typography style={{marginBottom: '1em', fontSize: '1.5em'}} color='primary'>
                        {data[id].class_name}
                      </Typography>
                      <CreateYoutube youtubeId={data[id].youtube_id} />
                      <InfoCol classes={classes} record={data[id]} />
                    </CardContent>
                  </Card>
                </GridListTile>
            ))}
        </MuiGridList>
        <div className={classes.bottom_div}>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            課程時間
          </Button>
          <Button
              variant="outlined" color="primary"
              style={{marginLeft:'32px'}}
              href="https://line.me/R/ti/p/%40qpx8269n"
          >
              LINE 報名
          </Button>
        </div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Button autoFocus color="inherit" onClick={handleClose}>
                CLOSE
              </Button>
            </Toolbar>
          </AppBar>
          <iframe
            style={{marginTop:'64px', height:'100%'}}
            src="https://calendar.google.com/calendar/embed?height=480&amp;wkst=2&amp;bgcolor=%23F4511E&amp;ctz=Asia%2FTaipei&amp;src=MjZjNDB1ZGQ3cXZyZ21iYmMzNmpvM2djcGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23F4511E&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=0&amp;showTz=0&amp;showCalendars=0&amp;mode=WEEK" >
            </iframe>
        </Dialog>
    </div>
  )
}



const ProductGrid = ({ loadedOnce, ...props }) =>
    loadedOnce ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

const enhance = compose(
    withWidth(),
    withStyles(styles)
);

export default enhance(ProductGrid);
