'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var yy_orange = {
  50: '#ff3e24',
  100: '#ff3e24',
  200: '#ff3e24',
  300: '#ff3e24',
  400: '#ff3e24',
  500: '#ff3e24',
  600: '#ff3e24',
  700: '#ff3e24',
  800: '#ff3e24',
  900: '#ff3e24',
  A100: '#ff3e24',
  A200: '#ff3e24',
  A400: '#ff3e24',
  A700: '#ff3e24'
};

exports.default = yy_orange;
