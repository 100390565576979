import React, {  useState, Component } from 'react';
import { Responsive, Title, GET_ONE, GET_MANY } from 'react-admin';
//import ApiCalendar from '../ApiGCal';
import NGSWelcome from './NGSWelcome'
import NowNews from './NowNews'
import DataProvider from '../dataProviders'
import MyPoints from './MyPoints'
import MyResv from './MyResv'
import ClassSum from './ClassSum'
import NowUsers from './NowUsers'
import firebase from 'firebase'
import { withRouter } from 'react-router'
import moment from 'moment-timezone'
import {GYM_NGS_ID, GYM_KYS_ID, GYM_ZXFS_ID, ZFS_LOC, NGS_LOC, KYS_LOC} from '../config'
import { geolocated } from "react-geolocated"
import TextField from '@material-ui/core/TextField'
import { translate } from 'react-admin'
import FirebaseDialog from './FirebaseDialog'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import PayIcon from '@material-ui/icons/Payment';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocSwitcher from './LocSwitcher'

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const nowDay = moment(new Date()).tz("Asia/Taipei").format('ddd')
const dataProvider = DataProvider();

class Dashboard extends Component {

  constructor(props){
    super(props)
    this.state = {checked:false,
                  gctChecked:false,
                  gptChecked:false,
                  yogaChecked:false,
                  ptChecked:false,
                  ptv2Checked:false,
                  rptChecked:false,
                  balance: 0,
                  free_gct: 0,
                  mins_left: 0,
                  balance_due: "",
                  workout_times: 0,
                  gct_times: 0,
                  rpt_times: 0,
                  yoga_times: 0,
                  pt_times: 0,
                  isLoading: true,
                  open: true,
                  news:[],
                  currentLoc:''
                  };
    this.refreshCheckedPoint = this.refreshCheckedPoint.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  refreshCheckedPoint(){
    firebase.auth().onAuthStateChanged(
      (user) => {
        //var name, email, photoUrl, uid, emailVerified;
          if(!user){
            this.props.history.push("/login")
          }else{
            console.log('refreshCheckedPoint GET_ONE')
            /***NOTE:
            We use null id to fetch data, and it's detected in RAFirebaseMethods
            Method will return resourData instead of resourceData['mydata']
            ***/
            dataProvider(GET_ONE, 'mydata', {})
              .then(response => {
              //NOTE: JSON.parse will change the orig data
              if(typeof response.data.balance !== 'number'){
                response.data.balance = 0
              }
              if(typeof response.data.acc_mins !== 'number'){
                response.data.acc_mins = 120
              }
              if(typeof response.data.checked !== 'boolean'){
                response.data.checked = false
              }
              if(typeof response.data.ptChecked !== 'boolean'){
                response.data.ptChecked = false
              }
              if(typeof response.data.gctChecked !== 'boolean'){
                response.data.gctChecked = false
              }
              if(typeof response.data.gptChecked !== 'boolean'){
                response.data.gptChecked = false
              }
              if(typeof response.data.yogaChecked !== 'boolean'){
                response.data.yogaChecked = false
              }
              if(typeof response.data.ptv2Checked !== 'boolean'){
                response.data.ptv2Checked = false
              }
              if(typeof response.data.rptChecked !== 'boolean'){
                response.data.rptChecked = false
              }
              if(typeof response.data.free_gct !== 'number'){
                response.data.free_gct = 0
              }
              if(typeof response.data.username !== 'string'){
                if(user.displayName){
                  response.data.username = user.displayName
                }else{
                  response.data.username = 'My Friend'
                }
              }
              var profile_picture = 'Avatar'
              if(typeof response.data.profile_picture === 'string'){
                profile_picture = response.data.profile_picture +'?height=512'
              }
              if(typeof response.data.usage !== 'object'){
                response.data.usage = ''
              }
              if(typeof response.data.currentLoc !== 'string'){
                response.data.currentLoc = ''
              }
              var workout_times=0, gct_times=0, rpt_times=0, pt_times=0, yoga_times = 0
              for (var prop in response.data.usage){
                var str = String(response.data.usage[prop].usageType)
                if(str.includes("WORKOUT")&&response.data.usage[prop].length>1){
                  workout_times++
                }
                if(str.includes("GCT")){
                  gct_times++
                }
                if(str.includes("RPT")){
                  rpt_times++
                }
                if(!str.includes("GPT") && str.includes("PT")){
                  pt_times++
                }
                if(str.includes("YOGA")){
                  yoga_times++
                }
              }
              console.log(workout_times+','+gct_times+','+pt_times+','+yoga_times)

              if(response.data.currentLoc == ''){
                var currentGymLoc = this.translateLocation(
                  this.props.coords?this.props.coords.latitude:0,
                  this.props.coords?this.props.coords.longitude:0)
                console.log('currentGymLoc:' + currentGymLoc)
                this.setState({currentLoc: currentGymLoc})
              }

              this.setState({
                balance: String(response.data.balance),
                mins_left: String(120-response.data.acc_mins),
                free_gct: String(response.data.free_gct),
                checked: response.data.checked,
                gctChecked:response.data.gctChecked,
                gptChecked:response.data.gptChecked,
                yogaChecked:response.data.yogaChecked,
                ptChecked:response.data.ptChecked,
                ptv2Checked:response.data.ptv2Checked,
                rptChecked:response.data.rptChecked,
                balance_due: response.data.balance_due,
                userName: response.data.username,
                photoURL: profile_picture,
                workout_times: workout_times,
                gct_times: gct_times,
                rpt_times: rpt_times,
                yoga_times: yoga_times,
                pt_times: pt_times,
                isLoading: false,
              })

              this.refreshNowNews()
            }).catch( err => {
              console.log('get user balance error: ' + err)
            })
          }
        }
      )
  }

  refreshNowNews(){
    console.log('refreshNowNews GET_MANY')
    dataProvider(GET_MANY, 'gifts', { ids:['-MOaqkN0d2CzlAPB4URC', '-MOm7h71JlEAtcZZHSC4', '-MOpFp3qKG3TixHbDeXJ'] })
    .then(response => {
      if(response.data){
        var news_array = []
        response.data.map(gift=>{
          console.log('gift:'+gift.title)
          console.log('gift:'+gift.cover[0].src)
          var linkToNews = 'https://localhost/#/gifts/'+gift.id+'/show'
          //var linkToNews = 'https://localhost/#/gifts/'
          news_array.push({
            title:gift.title,
            src:gift.cover[0].src,
            link:linkToNews})
         });
       this.setState({news:news_array})
       //console.log('setState:news:'+news_array)
       console.log('news.length:' + news_array.length)
      }else{
        console.log('response.data is not found:')
      }
    }).catch( err => {
      console.log('get news error: ' + err)
    })
  }

  componentWillMount() {
    this.refreshCheckedPoint()
  }

  translateLocation(lat, log){
    //console.log('lat:' + lat + ',log:' + log)
    var zfs_dist = Math.hypot(lat-ZFS_LOC.lat, log-ZFS_LOC.log)
    var ngs_dist = Math.hypot(lat-NGS_LOC.lat, log-NGS_LOC.log)
    var kys_dist = Math.hypot(lat-KYS_LOC.lat, log-KYS_LOC.log)
    const dist_dict = {
      zfs_dist:Math.hypot(lat-ZFS_LOC.lat, log-ZFS_LOC.log),
      ngs_dist:Math.hypot(lat-NGS_LOC.lat, log-NGS_LOC.log),
      kys_dist:Math.hypot(lat-KYS_LOC.lat, log-KYS_LOC.log)
    }

    const sortable = Object.fromEntries(
      Object.entries(dist_dict).sort(([,a],[,b]) => a-b)
    );
  //  console.log('sorted dist:' + Object.keys(sortable)[0])
    var currentLoc = ''
    switch(Object.keys(sortable)[0]){
      case 'zfs_dist':
        currentLoc = GYM_ZXFS_ID
        break
      case 'ngs_dist':
        currentLoc = GYM_NGS_ID
        break
      case 'kys_dist':
        currentLoc = GYM_KYS_ID
        break
    }

    console.log('currentLoc:' + currentLoc)
    return currentLoc
  }

  handleChange = name => event => {
      this.setState({[name]: event.target.checked });
    };

  switchToZFS = event => {
      this.setState({currentLoc: GYM_ZXFS_ID });
      console.log('currentLoc:' + this.state.currentLoc)
    };
  switchToNGS = event => {
      this.setState({currentLoc: GYM_NGS_ID });
      console.log('currentLoc:' + this.state.currentLoc)
    };
  switchToKYS = event => {
      this.setState({currentLoc: GYM_KYS_ID });
      console.log('currentLoc:' + this.state.currentLoc)
    };

  render() {
    const {
      photoURL,
      userName,
      balance,
      mins_left,
      balance_due,
      checked,
      gctChecked,
      gptChecked,
      yogaChecked,
      ptChecked,
      ptv2Checked,
      rptChecked,
      free_gct,
      currentLoc,
      workout_times,
      gct_times,
      rpt_times,
      yoga_times,
      pt_times,
      isLoading,
      news,
      open,
    } = this.state;

    return(
      <Responsive
        small={
            <div>
            <FirebaseDialog
              isLoading={isLoading}
              currentLoc={this.translateLocation(
                this.props.coords?this.props.coords.latitude:0,
                this.props.coords?this.props.coords.longitude:0)} />
            {balance==0&&mins_left==0?(''):(
              <div style={{ marginBottom: '2em' }}>
                <NGSWelcome photoURL={photoURL} userName={userName}
                  checked={checked} refresh={this.refreshCheckedPoint}
                  handleChange={this.handleChange}
                  switchToZFS={this.switchToZFS}
                  gptChecked={gptChecked}
                  gctChecked={gctChecked}
                  ptChecked={ptChecked}
                  ptv2Checked={ptv2Checked}
                  yogaChecked={yogaChecked}
                  rptChecked={rptChecked}
                  currentLoc={currentLoc} />
              </div>
              )
            }
            {balance==0&&mins_left==0?(''):(
              <div style={{ marginBottom: '2em' }}>
                <LocSwitcher switchToKYS={this.switchToKYS} switchToNGS={this.switchToNGS}
                  switchToZFS={this.switchToZFS} />
              </div>
              )
            }
            {balance==0&&mins_left==0?(''):(
              <div style={styles.flex}>
              <ClassSum isLoading={isLoading}
                workout_times={workout_times}
                gct_times={gct_times}
                pt_times={pt_times}
                rpt_times={rpt_times}
                yoga_times={yoga_times}
                />
              <MyPoints isLoading={isLoading} value={balance} due={balance_due}
                mins_left={mins_left} free_gct={free_gct} />
              <MyResv isLoading={isLoading} />
            </div>)}
          </div>
        }
        medium={
            <div style={styles.flex}>
              <FirebaseDialog
                isLoading={isLoading}
                currentLoc={this.translateLocation(
                  this.props.coords?this.props.coords.latitude:0,
                  this.props.coords?this.props.coords.longitude:0)} />
              <div style={styles.leftCol}>
                {balance==0&&mins_left==0?(''):(
                  <div style={styles.flex}>
                    <NGSWelcome photoURL={photoURL} userName={userName}
                    checked={checked} refresh={this.refreshCheckedPoint}
                    handleChange={this.handleChange}
                    gptChecked={gptChecked}
                    gctChecked={gctChecked}
                    ptChecked={ptChecked}
                    ptv2Checked={ptv2Checked}
                    yogaChecked={yogaChecked}
                    rptChecked={rptChecked}
                    currentLoc={currentLoc}
                    /*
                    currentLoc={this.translateLocation(
                      this.props.coords?this.props.coords.latitude:0,
                      this.props.coords?this.props.coords.longitude:0)}*/ />
                  </div>)
                  }
                {balance==0&&mins_left==0?(''):(
                  <div style={styles.flex}>
                    <ClassSum isLoading={isLoading}
                      workout_times={workout_times}
                      gct_times={gct_times}
                      pt_times={pt_times}
                      rpt_times={rpt_times}
                      yoga_times={yoga_times}
                      />
                    <MyPoints isLoading={isLoading} value={balance} due={balance_due}
                     mins_left={mins_left} free_gct={free_gct}/>
                    <MyResv isLoading={isLoading} />
                  </div>

                )
                }
              </div>
            </div>
        }
      />
    )
  }
}

export default (geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(translate(Dashboard)));
