import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LockOpenIcon from '@material-ui/icons/LockOpen';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { translate } from 'react-admin';
import UnlockButton from './UnlockButton'
import { green } from '@material-ui/core/colors';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    WithPermissions,
} from 'react-admin';
import {GYM_NGS_ID, GYM_ZXFS_ID, GYM_KYS_ID} from '../config'

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const NGSWelcome = ({ photoURL, userName, checked, refresh, classes, translate,
   gptChecked, gctChecked, yogaChecked, ptChecked, ptv2Checked, rptChecked,
   handleChange, switchToZFS, currentLoc }) => (
    <Card>
      <CardHeader
        titleTypographyProps={{style:{fontSize:'1em'}}}
        subheaderTypographyProps={{style:{fontSize:'1em'}}}
        avatar={
          <Avatar src={photoURL!=='Avatar'?photoURL:"https://fakeimg.pl/512/?text=Your%20Photo"}
          />
        }
        title={userName}
        subheader={translate('pos.dashboard.welcome.' + currentLoc)}
      />
        <CardContent>
            {currentLoc===GYM_NGS_ID&&
              <div>
                <FormLabel component="legend" style={{ marginTop: '10px' }}>
                  {translate('pos.dashboard.welcome.select_course')}</FormLabel>

                <FormGroup row>
                 <FormControlLabel disabled={checked}
                   label={translate('pos.dashboard.welcome.gct')}
                   control={
                     <Checkbox
                       checked={gctChecked}
                       onChange={handleChange('gctChecked')}
                       value="gctChecked"
                       />} />
                 <FormControlLabel disabled={checked}
                   label={translate('pos.dashboard.welcome.yoga')}
                   control={
                     <Checkbox
                       checked={yogaChecked}
                       onChange={handleChange('yogaChecked')}
                       value="yogaChecked"
                       />} />
                 <FormControlLabel disabled={checked}
                   label={translate('pos.dashboard.welcome.ptv2')}
                   control={
                     <Checkbox
                       checked={ptv2Checked}
                       onChange={handleChange('ptv2Checked')}
                       value="ptv2Checked"
                       />} />
                 <FormControlLabel disabled={checked}
                   label={translate('pos.dashboard.welcome.pt')}
                   control={
                     <Checkbox
                       checked={ptChecked}
                       onChange={handleChange('ptChecked')}
                       value="ptChecked"
                       />} />
                 <FormControlLabel disabled={checked}
                   label={translate('pos.dashboard.welcome.rpt')}
                   control={
                     <Checkbox
                       checked={rptChecked}
                       onChange={handleChange('rptChecked')}
                       value="rptChecked"
                       />} />
               </FormGroup>
            </div>
          }
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            {currentLoc===GYM_NGS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_ngs_button_in')}
                unlock="ngs_in"
                gptChecked={gptChecked}
                gctChecked={gctChecked}
                ptChecked={ptChecked}
                ptv2Checked={ptv2Checked}
                yogaChecked={yogaChecked}
                rptChecked={rptChecked}
                disabled={checked}
                refresh={refresh}
              />
            }
            {currentLoc===GYM_NGS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_ngs_button_out')}
                unlock="ngs_out"
                disabled={!checked}
                refresh={refresh}
              />
            }
            {currentLoc===GYM_ZXFS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_1f_button')}
                unlock="zxfs_1f"
                refresh={refresh}
              />
            }
            {currentLoc===GYM_ZXFS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_2f_button_in')}
                unlock="zxfs_2f_in"
                disabled={checked}
                refresh={refresh}
              />
            }
            {currentLoc===GYM_ZXFS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_2f_button_out')}
                unlock="zxfs_2f_out"
                disabled={!checked}
                refresh={refresh}
              />
            }
            {currentLoc===GYM_KYS_ID&&
              <UnlockButton
                label={translate('pos.dashboard.welcome.door_ngs_button_in')}
                unlock="kys_zx"
                disabled={checked}
                refresh={refresh}
              />
            }
            {currentLoc===GYM_ZXFS_ID&&
              <WithPermissions
                    render={({ permissions }) => (
                        permissions
                            ? <UnlockButton
                              label={translate('pos.dashboard.welcome.door_red_button')}
                              unlock="red"
                              refresh={refresh}
                            />
                            : null
                    )}
                />
            }
        </CardActions>
    </Card>
);

export default (translate(NGSWelcome));
