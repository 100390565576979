import React from 'react';
import {
    Edit,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
    LongTextInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';

import { styles as createStyles } from './TrainerCreate';

const TrainerTitle = ({ record }) => <span>Poster #{record.reference}</span>;

const styles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const TrainerEdit = ({ classes, ...props }) => (
    <Edit {...props} title={<TrainerTitle />}>
        <TabbedForm>
            <FormTab label="resources.trainers.tabs.image">
                <ImageInput source="avatar" label="Avatar" accept="image/*">
                  <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="resources.trainers.tabs.details" path="details">
              <TextInput source="trainer_name" validate={required()} />
              <TextInput source="trainer_title" validate={required()} />
              <TextInput source="trainer_slogan"  />
            </FormTab>
            <FormTab
                label="resources.trainers.tabs.social_link"
                path="ig_html"
            >
              <TextInput source="ig_link"  />
              <TextInput source="fb_link"  />
              <TextInput source="li_link"  />
            </FormTab>
            <FormTab
                label="resources.trainers.tabs.description"
                path="description"
            >
              <RichTextInput
                toolbar={[
                  ['bold', 'italic', 'underline', 'link'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'align': [] }], ]}
                source="description" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default withStyles(styles)(TrainerEdit);
