import React from 'react';
import {
    Edit,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
    LongTextInput,
    ReferenceInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';
import TrainerSelectInput from '../trainers/TrainerSelectInput'

import { styles as createStyles } from './OrderCreate';


const styles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const OrderEdit = ({ classes, ...props }) => (
    <Edit {...props}>
      <TabbedForm>
          <FormTab label="resources.Orders.tabs.details">
            <TextInput source="rcvName" validate={required()} />
            <TextInput source="rcvAmt" validate={required()} />
            <TextInput source="rcvTotal" validate={required()} />
            <NumberInput
                source="price"
                validate={required()}
            />
            <SelectInput
                source="price_unit"
                validate={required()}
                defaultValue={'point'}
                choices={[
                  { id: 'ntd', name: 'NTD' },
                  { id: 'point', name: 'point' },
                  { id: 'usd', name: 'USD' },
                ]}
            />
            <NumberInput
                source="length"
                validate={required()}
                defaultValue={60}
            />
            <NumberInput
                source="series_amount"
                validate={required()}
                defaultValue={1}
            />
            <NumberInput
                source="seat_ceil"
                validate={required()}
                defaultValue={6}
            />
            <NumberInput
                source="seat_floor"
                validate={required()}
                defaultValue={4}
            />
            <ReferenceInput source="location_id" reference="gyms">
              <SelectInput optionText="location" />
            </ReferenceInput>
          </FormTab>
          <FormTab
              label="resources.Orders.tabs.description"
              path="description"
          >
              <RichTextInput source="description" addLabel={false} />
          </FormTab>
      </TabbedForm>
    </Edit>
);

export default withStyles(styles)(OrderEdit);
