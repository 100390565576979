
import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EditButton,
    Filter,
    List,
    BooleanField,
    NumberField,
    Responsive,
    SearchInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const TrackingFilter = props => (
    <Filter {...props}>
        <DateInput source="last_unlock_datetime" />
    </Filter>
);

const listStyles = {
    nb_commands: { color: 'purple' },
};


const TrackingList = ({ classes, ...props }) => {
  return(
    <List
      {...props}
      filters={<TrackingFilter />}
      sort={{ field: 'createdAt', order: "DSC" }}
      perPage={10}
    >
      <Datagrid>
        <DateField source="createdAt" options={{ month: 'numeric', day: 'numeric' }}/>
        <NumberField source="bw" />
        <NumberField source="sleep_sts" />
        <NumberField source="digest_sts" />
        <NumberField source="exe_sts" />
        <NumberField source="training_sts" />
        <NumberField source="energy_sts" />
        <EditButton />
      </Datagrid>
    </List>
)};

export default withStyles(listStyles)(TrackingList);
