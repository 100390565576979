import ProductIcon from '@material-ui/icons/Collections';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import ProductShow from './ProductShow';
import ProductCreate from './ProductCreate';

export default{
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProductShow,
  icon: ProductIcon,
}
