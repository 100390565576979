import React, { Component } from 'react';
import {
    translate,
    AutocompleteInput,
    TextInput,
    DateInput,
    Create,
    ReferenceInput,
    SimpleForm,
    FormDataConsumer,
    SelectInput,
    List,
    TextField,
    Datagrid,
    required,
    Toolbar,
    SaveButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import firebase from 'firebase'
import EventSelectInput from '../events/EventSelectInput'
import { change } from 'redux-form'
import ScheduleIcon from '@material-ui/icons/Schedule'


const editStyles = {
    root: { alignItems: 'flex-start' },
};

const UserCreateToolbar = ({ permissions, ...props }) =>
    <Toolbar {...props}>
        <SaveButton
            label="user.action.reserve"
            submitOnEnter={false}
            icon={<ScheduleIcon />}
        />
    </Toolbar>;

class BookingCreate extends Component{
  state = {};
  componentDidMount(){
    firebase.auth().onAuthStateChanged(function(user) {
      if(user){
        console.log(user.uid)
        this.setState({client_id:user.uid})
      }else{
        console.log('no uid')
      }
    }.bind(this));
  }

  render() {
    const {
      client_id,
    } = this.state;
    return(
      <Create {...this.props}>
          <SimpleForm form="createBooking" redirect="list" toolbar={<UserCreateToolbar />}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>formData&&
                <ReferenceInput source="class_name" reference="t_events"
                 onChange={() => rest.dispatch(change("createBooking", "event_id", ""))} validate={required()} >
                  <SelectInput optionText="class_name" optionValue="class_name" resettable />
                </ReferenceInput>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData&&formData.class_name&&
                   <ReferenceInput source="trainer_name" reference="t_events" validate={required()} filter={{ class_name: formData.class_name}}>
                     <SelectInput optionText="trainer_name" optionValue="trainer_name"  resettable/>
                   </ReferenceInput>

                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>formData&&formData.class_name&&formData.trainer_name&&
                     <ReferenceInput source="event_id" reference="t_events"
                      filter={{ trainer_name: formData.trainer_name }} validate={required()}>
                       <SelectInput optionText="start__datetime"  resettable />
                     </ReferenceInput>
                }
            </FormDataConsumer>
            {this.props.permissions?<ReferenceInput perPage={100}
              source="client_id" reference="customers" filter={{balance:0}} >
              <AutocompleteInput optionText="username"  />
            </ReferenceInput>
              :<TextInput source="client_id" defaultValue={client_id} style={{display:'none'}}/>}
            <TextInput source="status" defaultValue="booked" style={{display:'none'}}/>
          </SimpleForm>
      </Create>
    )
  }
}

export default withStyles(editStyles)(BookingCreate);
