import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider';
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'
import DirectionsIcon from '@material-ui/icons/Directions'
import {
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    ImageField,
    CardActions,
    EditButton,
    DeleteButton,
    RichTextField,

} from 'react-admin'; // eslint-disable-line import/no-unresolved
import Button from '@material-ui/core/Button';
import { CreateButton } from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const styles = theme => ({
  showlayout:{
    display: 'block',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    textAlign: 'center',
  },
  flexContainer:{
    width: '100%',
    display: 'flex',
    margin: '0.5em 0.1em 0.5em 0.1em',
    justifyContent: 'center',
  },
  iconStyle:{
    fontSize: '2em',
    padding: '0 0.2em 0 0',
    color: 'grey',
    textAlign: 'left',
  },
  rightPadding:{
    padding: '0 1em 0 0',
    color: 'grey',
    fontSize: '1.5em',
    textAlign: 'center',
  },
  class_name:{
    fontSize: '1.5em',
    margin: '0em 0.1em 0.5em 0.1em',
  }
})

const utb_opts = {
      height: '100%',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
};

const ProductShowActions = ({ permissions, basePath, data, resource }) => (
    <CardActions style={{index:-2}}>
        {permissions === 'leaper' &&
            <EditButton basePath={basePath} record={data} />
          }
        {permissions === 'leaper' &&
            <DeleteButton basePath={basePath} record={data} resource={resource} />
        }
    </CardActions>
);

const ProductTitle = ({ record }) => {
    return <span>{record ? `${record.class_name}` : ''}</span>;
};

const ShowAvatar = ({ record, source }) => {
  return (
    <Avatar
      src={record[source][0].src}
      style={{width:100,height:100,display: 'block',margin: 'auto'}}
    />
  );
};

const IGHtml = ({ record }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: record.ig_html }}
    />
  );
}

const CreateYoutube= ({ record }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${record.youtube_id}`}
        frameBorder="0"
      />
    </div>
  )
}

const InfoCol =  ({ record, classes }) => {
  return(
    <div className={classes.flexContainer}>
      <MoneyIcon className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.price}` : ''}</span>
      <TimeIcon className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.length}` : ''}</span>
      <PersonIcon className={classes.iconStyle} />
        <span className={classes.rightPadding}>{record ? `${record.seat_floor}~${record.seat_ceil}` : ''}</span>
    </div>
  )
}

const ProductShow = ({ classes, permissions, ...props }) => (
    <Show  {...props} className={classes.showlayout}>
        <SimpleShowLayout className={classes.showlayout}>
          <TextField color="primary" source="class_name" addLabel={false} className={classes.class_name}/>
          <CreateYoutube />
          <InfoCol classes={classes} />
          <RichTextField source="description" addLabel={false} />
        </SimpleShowLayout>
    </Show>
);

export default withStyles(styles)(ProductShow);
