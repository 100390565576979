import React, { Component } from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    ReferenceInput,
    TextInput,
    SelectInput,
    NumberInput,
    translate,
} from 'react-admin';
import moment from 'moment-timezone'
import withStyles from '@material-ui/core/styles/withStyles';

const dateParser = v => {
  const timezone_RFC = moment.tz(v, "Asia/Taipei").format()
  console.log('timezone_RFC:' + timezone_RFC)
  return timezone_RFC
}

const dateFormater = v => {
  const datetime_RFC3339= moment(v).tz("Asia/Taipei").format(moment.HTML5_FMT.DATETIME_LOCAL)
  console.log('datetime_RFC3339:' + datetime_RFC3339)
  return datetime_RFC3339
}

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.full_name) {
        errors.full_name = ['The customer name is required'];
    }
    if (!values.start__datetime) {
        errors.start__datetime = ['Time is required'];
    }

    if (!values.status) {
        errors.status = ['Status is required'];
    }

    if (!values.summary) {
        errors.status = ['Summary is required'];
    }
    return errors
};

class EventEdit extends Component{
  state = {};

  render() {
    const {
    } = this.state;

    return(
      <Edit {...this.props}>
          <TabbedForm>
            <FormTab label="Event Edit">
                <TextInput source="userId" disabled/>
                <TextInput
                  style={{width:'90%'}}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  source="resTimestamp"
                  parse={dateParser}
                  format={dateFormater}
                  label="Start Time"/>
                <TextInput source="resType" />
                <TextInput source="status" disabled />
              </FormTab>
          </TabbedForm>
      </Edit>
    )
  }
}
export default translate(EventEdit)
