import React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

export const styles = {
    stock: { width: '5em' },
    price: { width: '5em' },
    width: { width: '5em' },
    widthFormGroup: { display: 'inline-block' },
    height: { width: '5em' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const TermCreate = ({ classes, ...props }) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <TextInput source="content" validate={required()} />          
        </SimpleForm>
    </Create>
);

export default withStyles(styles)(TermCreate);
