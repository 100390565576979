import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { NumberField, TextField, DateField, ReferenceField, EditButton } from 'react-admin';
import { linkToRecord } from 'ra-core';
import {Card, CardHeader, CardMedia, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


import Avatar from '@material-ui/core/Avatar'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    root: {
      margin: '-0.4em',
      display: 'block',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    gridList: {
        width: '100%',
        margin: 0,
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    price: {
        display: 'inline',
        fontSize: '1em',
    },
    link: {
        color: '#fff',
    },
    cardStyle: {
      width: '100%',
      margin: '0.2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    flexContainer:{
      width: '150px',
      display: 'flex',
      flexDirection: 'row',
    },
    iconStyle:{
      fontSize: '1em',
      padding: '0 0.2em 0 0',
      color: 'grey',
      textAlign: 'left',
    },
    rightPadding:{
      padding: '0 1em 0 0',
      color: 'grey',
      fontSize: '1em',
      textAlign: 'center',
    },
    classTitle:{
      color: theme.palette.grey[300],
    },
    bigAvatar: {
      width: 72,
      height: 72,
    },
    media: {
      display: 'block',
      overflow: 'hidden',
    },
    title:{
      color: 'black',
      fontSize: '16pt',
    }
});

const getColsForWidth = width => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 2;
    if (width === 'lg') return 3;
    return 3;
};

const getHeightForGrid = width => {
    if (width === 'xs') return 160;
    if (width === 'sm') return 192;
    if (width === 'md') return 288;
    if (width === 'lg') return 396;
    return 200;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const LoadedGridList = ({ classes, ids, data, basePath, width }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {ids.map(id => (
                <GridListTile key={id} style={{ height: '100%' }} component={Link}
                 to={`${linkToRecord(basePath, data[id].id)}/show`}>
                  <Card className={classes.cardStyle}>
                    <CardHeader
                      classes={{
                          title: classes.title,
                        }}
                      title={data[id].trainer_name}
                      subheader={data[id].trainer_title}
                      avatar={<Avatar src={data[id].avatar[0]?data[id].avatar[0].src:data[id].avatar.src}
                        className={classes.bigAvatar}/>}
                    />
                    <div
                      className={classes.media}
                      dangerouslySetInnerHTML={{ __html: data[id].ig_html }}
                    />
                    </Card>
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const TrainerGrid = ({ loadedOnce, ...props }) =>
    loadedOnce ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

const enhance = compose(
    withWidth(),
    withStyles(styles)
);

export default enhance(TrainerGrid);
