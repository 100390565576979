import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    WithPermissions,
    Responsive,
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import PostIcon from '@material-ui/icons/Book';
import InfoIcon from '@material-ui/icons/Info';
import PayIcon from '@material-ui/icons/Payment';
import CustomerIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';

const Menu = ({ onMenuClick, translate, logout }) => (
    <div>
        <DashboardMenuItem onClick={onMenuClick} primaryText={translate(`pos.dashboard.name`)}/>
        <MenuItemLink
            to="/trainers"
            primaryText={translate(`resources.trainers.name`, {
                smart_count: 2,
            })}
            leftIcon={<SearchIcon />}
            onClick={onMenuClick}
        />
        <MenuItemLink
            to="/products"
            primaryText={translate(`resources.products.name`, {
                smart_count: 2,
            })}
            leftIcon={<InfoIcon />}
            onClick={onMenuClick}
            />
        <WithPermissions
              render={({ permissions }) => (
                  permissions==="leaper"
                      ? <MenuItemLink
                          to="/reservations"
                          primaryText={translate(`resources.events.name`)}
                          leftIcon={<InfoIcon />}
                          onClick={onMenuClick}
                      />
                      : <MenuItemLink
                          to="/myreservations"
                          primaryText={translate(`resources.events.name`)}
                          leftIcon={<InfoIcon />}
                          onClick={onMenuClick}
                      />
              )}
          />
        <MenuItemLink
            to="/my-profile"
            primaryText={translate(`resources.profile.name`)}
            leftIcon={<InfoIcon />}
            onClick={onMenuClick}
        />
        <WithPermissions
              render={({ permissions }) => (
                  permissions==="leaper"
                      ? <MenuItemLink
                          to="/customers"
                          primaryText={translate(`resources.customers.name`, {
                              smart_count: 2,
                          })}
                          leftIcon={<CustomerIcon />}
                          onClick={onMenuClick}
                          />
                      : null
              )}
          />
        <MenuItemLink
            to="/terms"
            primaryText={translate(`resources.terms.name`, {
                smart_count: 2,
            })}
            leftIcon={<PostIcon />}
            onClick={onMenuClick}
        />

        <Responsive xsmall={logout} medium={null} />
    </div>
);

/* will be added it back after ProfileShow finished
 <MenuItemLink
      to="/my-profile"
      primaryText={translate(`resources.profile.name`)}
      leftIcon={<CustomerIcon />}
      onClick={onMenuClick}
  />
*/

const enhance = compose(
    withRouter,
    connect(
        state => ({
            theme: state.theme,
            locale: state.i18n.locale,
        }),
        {}
    ),
    translate
);

export default enhance(Menu);
