import React from 'react';
import {
    List,
    CreateButton,
    CardActions,
} from 'react-admin';
import TrainerGrid from './TrainerGrid';

const TrainerActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
      <CreateButton basePath={basePath} />
    </CardActions>
);

const TrainerList = ({permissions, ...props}) => (
  <List
    {...props}
    perPage={20}
   actions={permissions?<TrainerActions {...props}/>:null}>
    <TrainerGrid />
  </List>
);

export default TrainerList;
