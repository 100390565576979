import React from 'react';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    card: {
        float: 'left',
        margin: '-20px 20px 0 15px',
        zIndex: 100,
        borderRadius: 3,
    },
    icon: {
        float: 'right',
        width: 32,
        height: 32,
        padding: 8,
        color: '#fff',
    },
    button: {
        float: 'center',
        padding: 4,
        color: '#fff',
    },
};

const CardIcon = ({ Icon, classes, bgColor, href }) => (
    <Card className={classes.card} style={{ backgroundColor: bgColor }}>
      <IconButton aria-label="Buy" href={href}
      className={classes.button}>
        <Icon className={classes.icon}  />
      </IconButton>
    </Card>
);

export default withStyles(styles)(CardIcon);
