//import gcalDataProvider from './gcalDataProvider';
import RestProvider from './firebase-client/RestProvider';
//import RestProvider from './firebase-client/restClient';
import firebase from "firebase";
import {GYM_NGS_ID} from './config'

/* formal */
const config = {
  apiKey: "AIzaSyA4XCnGKr9qIq-Cm70Ch8Ov6AOzqi7oi9M",
  authDomain: "app.leapfitz.com",
  databaseURL: "https://leapfitz.firebaseio.com",
  projectId: "leapfitz",
  storageBucket: "leapfitz.appspot.com",
  messagingSenderId: "34954180039"
};


/* testing repo
const config = {
  apiKey: "AIzaSyAGV82DqyGIpZmrDfiEZPHGpPiO3h3P0ys",
  authDomain: "ap.leapfitz.com",
  databaseURL: "https://myawep.firebaseio.com",
  projectId: "myawep",
  storageBucket: "myawep.appspot.com",
  messagingSenderId: "327031269615",
  appId: "1:327031269615:web:3945f43a32905d0f76931a"
};
*/
firebase.initializeApp(config);

const clientOptions = {
  timestampFieldNames: {
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
  },
  trackedResources: [
    {
      // Must set public as false to override the path to users/'uid'
      name: 'customers', // The name reference to be used in all other places in AOR
      path: 'users', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'reservations', // The name reference to be used in all other places in AOR
      path: 'reservations', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'myreservations', // The name reference to be used in all other places in AOR
      path: 'reservations', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    /*
    {
      name: 't_events', // The name reference to be used in all other places in AOR
      path: 't_events', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    */
    {
      name: 'reviews', // The name reference to be used in all other places in AOR
      path: 'terms', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'mydata', // The name reference to be used in all other places in AOR
      path: 'users', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'products', // The name reference to be used in all other places in AOR
      path: 'products', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'orders', // The name reference to be used in all other places in AOR
      path: 'orders', // The path in the database. If missing will use the name
      isPublic: false,
    },
    {
      name: 'myorders', // The name reference to be used in all other places in AOR
      path: 'orders', // The path in the database. If missing will use the name
      isPublic: false,
    },
    {
      name: 'trainers', // The name reference to be used in all other places in AOR
      path: 'trainers', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: ['avatar'] // The string name of the field
    },
    /*
    {
      name: 'mytracking', // The name reference to be used in all other places in AOR
      path: 'trackings', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: ['photo_front','photo_back','photo_left','photo_right'] // The string name of the field
    },
    */
    {
      name: 'gyms', // The name reference to be used in all other places in AOR
      path: 'gyms', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'plans', // The name reference to be used in all other places in AOR
      path: 'plans', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: ['cover'] // The string name of the field
    },
    {
      name: 'gifts', // The name reference to be used in all other places in AOR
      path: 'plans', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: ['cover'] // The string name of the field
    },
    /* duplicate resource will make permissions denied errors
    {
      name: 'mybooking', // The name reference to be used in all other places in AOR
      path: 'bookings', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    */
    {
      name: 'bookings', // The name reference to be used in all other places in AOR
      path: 'bookings', // The path in the database. If missing will use the name
      isPublic: false,
      uploadFields: [] // The string name of the field
    },
    {
      name: 'terms', // The name reference to be used in all other places in AOR
      path: 'terms', // The path in the database. If missing will use the name
      isPublic: true,
      uploadFields: [] // The string name of the field
    },
  ] // A single string assumes path and name as equal, non private and without upload fields
}

/*
const dataProviders = [
    { dataProvider: restClient(clientOptions.trackedResources, clientOptions), resources: ['customers'] },
    { dataProvider: gcalDataProvider(), resources: ['events'] },
];
*/

export default (type, resource, params) => {
    //const dataProvider = dataProviders.find(dp => dp.resources.includes(resource));

    //return dataProvider(type, resource, params);
    return RestProvider(clientOptions.trackedResources, clientOptions)
}
