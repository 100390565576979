import firebase from 'firebase'





const leapfitzUrl = 'https://pt.leapfitz.com:8080'
const leapngsUrl = 'https://ngg.leapfitz.com:7890'
//const leapngsUrl = 'http://localhost:8087'
const doorRedUrl = leapfitzUrl +'/leapjxfzred'
const door1fUrl = leapfitzUrl +'/leapjxfz1f'
const door2fUrl = leapfitzUrl +'/leapjxfz2f'
const doorngsUrl = leapngsUrl +'/leapngsgate'

// Returns the signed-in user's display name.
function getUserName() {
  return firebase.auth().currentUser.displayName;
}

// Saves a new message on the Cloud Firestore.
function saveMessage(messageText) {
  // Add a new message entry to the Firebase database.
  return firebase.firestore().collection('messages').add({
    name: getUserName(),
    uid: firebase.auth().currentUser.uid,
    text: messageText,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  }).catch(function(error) {
    console.error('Error writing new message to Firebase Database', error);
  });
}

export default ( key, callback, refresh, gptChecked,
gctChecked,
ptChecked,
ptv2Checked,
yogaChecked,
rptChecked ) => {
  //console.log('handle Unlock ' + key)
  //console.log('gptChecked:'+gptChecked+'\ngctChecked:'+gctChecked+'\nptChecked:'+ptChecked+'\nyogaChecked:'+yogaChecked)
  if(!firebase.auth().currentUser){
    callback("Please refresh your page","warning")
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          callback("User login")
        } else {
          callback("Login failed","warning")
        }
      });
    }else{
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(token) {
      var req = new XMLHttpRequest();
      req.onload = function() {
        callback(req.responseText)
        // Addind delay for waiting data consumption on Firebase
        setTimeout(
            function() {
                refresh()
            }
            .bind(this),
            2000
        );

      }//.bind(this);
      req.onerror = function() {
        if(req.responseText===""){
          callback("Server Error! Contact LEAP Facebook","warning")
        }else{
          callback(req.responseText,"warning")
        }
      }//.bind(this);

      // prepare booking vars
      //var ref = firebase.database().ref("events");
      //var now_date = moment(new Date()).format("MMM DD, YYYY")
      //var unlocked = false
      var sendReq = true
      switch(key){
        case 'red':
          req.open('GET', doorRedUrl, true);
          break;
        case 'zxfs_1f':
          req.open('GET', door1fUrl, true);
          break;
        case 'zxfs_2f_in':
          req.open('GET', door2fUrl, true);
          break;
        case 'zxfs_2f_out':
          req.open('GET', door2fUrl, true);
          break;
        case 'ngs_in':
          req.open('GET', doorngsUrl, true);
          break;
        case 'ngs_out':
          req.open('GET', doorngsUrl, true);
          break;
        case 'kys_zx':
          sendReq = false
          console.log('kys zx')
          saveMessage(key, token)

          break;
        default:
          sendReq = false
      }
      if(sendReq){
        req.setRequestHeader('Authorization', 'Bearer ' + token);
        req.setRequestHeader('Key', key);
        if(key==='ngs_in'){
          console.log('gptChecked,  gctChecked,  ptv2Checked,  rptChecked ' + gptChecked
          + gctChecked + ptv2Checked + rptChecked)
          req.setRequestHeader('gptChecked', gptChecked);
          req.setRequestHeader('gctChecked', gctChecked);
          req.setRequestHeader('ptChecked', ptChecked);
          req.setRequestHeader('ptv2Checked', ptv2Checked);
          req.setRequestHeader('yogaChecked', yogaChecked);
          req.setRequestHeader('rptChecked', rptChecked);
        }
        req.send()
      }
    });
  }
}
