import TrackingIcon from '@material-ui/icons/AttachMoney';

import TrackingList from './TrackingList';
import {TrackingEdit, TrackingCreate} from './Trackings';

export default {
    list: TrackingList,
    edit: TrackingEdit,
    icon: TrackingIcon,
    create: TrackingCreate,
};
