import { AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import firebase from 'firebase'
//import ApiCalendar from './ApiGCal';

var fb_auth_provider = new firebase.auth.FacebookAuthProvider();

const baseConfig = {
  userProfilePath: "/users/",
  //localStorageTokenName: "aorFirebaseClientToken",
  handleAuthStateChange: async (auth, config) => {
    if (auth) {
      const user = auth.user;
      const snapshot = await firebase
        .database()
        .ref(config.userProfilePath + user.uid)
        .once("value");
      const profile = snapshot.val();

      if (profile) {
        const firebaseToken = await user.getIdToken()
        let user = { auth, profile, firebaseToken }
        //localStorage.setItem(config.localStorageTokenName, firebaseToken)
        return user;
      } else {
        firebase.auth().signOut();
      }
    }
    //localStorage.removeItem(config.localStorageTokenName);
    //      throw new Error('sign_in_error');
    return false;
  }
};

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
      const { username, password, displayName } = params;
      if(username === undefined && password===undefined){
        return firebase.auth().signInWithRedirect(fb_auth_provider).then(function(){
          console.log('finish')
        });
      }else{
        console.log('username:' + username + ',displayName:' + displayName )
        if(displayName===undefined){
          return firebase.auth().signInWithEmailAndPassword(username, password);
        }else{
          return firebase.auth().createUserWithEmailAndPassword(username, password).then(function(user) {
              // [END createwithemail]
              // callSomeFunction(); Optional
              var user = firebase.auth().currentUser;
              user.updateProfile({
                  displayName: displayName
              }).then(function() {
                  // Update successful.
              }, function(error) {
                  // An error happened.
                  console.error('displayName err:' + error + ',' + displayName);
              });
          }, function(error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // [START_EXCLUDE]
              if (errorCode == 'auth/weak-password') {
                  alert('The password is too weak.');
              } else {
                  console.error(error);
                  return Promise.reject(error);
              }
              // [END_EXCLUDE]
          });
        }
      }
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
      localStorage.removeItem('fbEmail');
      localStorage.removeItem('fbName');
      localStorage.removeItem('fbPhoto');
      localStorage.removeItem('user_token');
      return firebase.auth().signOut().then(function() {
        console.log('fb logout suc')
        //ApiCalendar.handleSignoutClick();
        return Promise.resolve();
      }).catch(function(error) {
        console.log('fb logout err:' + error.message)
        return Promise.resolve();
      });

    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
      console.log('AUTH_ERROR:' + params)
        const { status } = params;
        if (status === 401 || status === 403) {
          localStorage.removeItem('fbEmail');
          localStorage.removeItem('fbName');
          localStorage.removeItem('fbPhoto');
          localStorage.removeItem('user_token');
          return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
      console.log('START AUTH CHK')
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            localStorage.setItem('fbEmail', user.email);
            localStorage.setItem('fbName', user.displayName);
            localStorage.setItem('fbPhoto', user.photoURL);
            //ApiCalendar.handleAuthClick();
            // Query to check if user exists
            firebase.database().ref('/users/' + user.uid).once('value').then(function(snapshot) {
              if(snapshot.val()===null||snapshot.val()===''){
                // create base for new user
                  firebase.database().ref('/users/' + user.uid).set({
                  username: user.displayName,
                  email: user.email,
                  profile_picture : user.photoURL,
                  balance: 0,
                  checked: false,
                  gctChecked:false,
                  gptChecked:false,
                  yogaChecked:false,
                  ptChecked:false,
                  ptv2Checked:false,
                  rptChecked:false,
                  chinese_fullName: '',
                  acc_mins: 120,
                  free_gct: 0,
                }).catch(function(error){
                  console.log('set user error:' + error)
                });
              }else{
                // set local storage
                var c_username = (snapshot.val() && snapshot.val().chinese_fullName) || '';
                var admin_token = (snapshot.val() && snapshot.val().user_token) || '';
                localStorage.setItem('chFullName', c_username);
                if(admin_token !== ''){
                  localStorage.setItem('user_token', admin_token);
                }
              }
            });

            return Promise.resolve()
          } else {
            // No user is signed in.
            console.log('Not signed in yet')
            return Promise.reject('Signin required');
            //return Promise.reject("Signin required").catch(function(error){
              //console.log('error:' + error)
            //})
          }
      },
      function(error){
        console.error(error);
        return Promise.reject(error);
      });
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('user_token');
        return Promise.resolve(role);
    }
    // Passing thru when it waits for getRedirectResult in Login.js
    return Promise.resolve();
};
