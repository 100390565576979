import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
    pos: {
        noFbChk: 'Email Login',
        newUser: 'New User',
        email: 'email',
        displayName: 'Your Name',
        register: 'Register',
        facebook: 'Sign-In By Facebook',
        search: 'Search',
        register: 'register',
        login: 'login',
        configuration: 'Configuration',
        language: 'Language',
        iforget: 'Forget password?',
        sendRecoveryEmail:'Send Recovery Email',
        emailSent: 'E-mail is sent',
        emailError: 'Sending failed',
        selectPay: 'Select Payment',
        checkout: 'CHECKOUT',
        payOnline: 'ONLINE',
        payOnthego: 'CASH',
        payOnthegoHint: 'GO AND PAY',
        payByTransfer: 'TRANSFER',
        price: 'Price',
        nowNow: 'Not now',
        needLogin: 'Login to proceed',
        select: 'CHECKOUT',
        valueAdded: 'Point Added',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            name: 'Dashboard',
            monthly_usage: 'Monthly Usage',
            my_points: 'My Points',
            mins_left: 'Minutes Left',
            free_gct_left: 'Free GCT',
            loading: 'loading...',
            due_date: 'Due Date',
            new_orders: 'New Orders',
            new_customers: 'New Customers',
            now_users: 'Now Users',
            now_using: 'Gym are reserved for Yoga 19:40-20:50.',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome',
                sub_title: ' To',
                ZFS: ' 忠孝復興',
                NGS: ' 南港',
                select_course: 'Check courses before entering (if have)',
                workout: 'Workout',
                times: ' times',
                classes: ' classes',
                gpt: 'Grouped Training',
                gct: 'Circuit Training',
                yoga: 'Yoga',
                pt: '1v1 PT',
                ptv2: '1v2 PT',
                rpt: 'Rent for PT',
                no_access: 'NO ACCESS!',
                door_button: 'Open Leap Door',
                door_red_button: 'PT Room',
                door_1f_button: 'Open Leap 1F',
                door_2f_button_in: 'Enter LEAP',
                door_2f_button_out: 'Leave LEAP',
                door_ngs_button_in: 'Check-IN',
                door_ngs_button_out: 'Check-OUT',
                demo_button: 'Contact Leap',
                door_openning: 'Opening...',
                door_granted: 'Access Granted',
                door_earlylate: 'Early or Late than 10 mins',
                door_nobooking: 'Booking Not Found',
                door_error: 'Error, Contact us on LEAP Facebook',
                server_error: 'Contact LEAP @LINE for HELP',
                booking_title: 'Booking Here:',
                already_checked: 'Already Checked-In',
                left_no_deduct: 'No Deduct',
                left_point_deduct: 'Points Deducted',
                fully_occupied: 'Fully occupied. Please enter later.',
                welcome_checkin_deduct: 'Welcome! Point Dedcuted',
                welcome_checkin: 'Welcome to LEAP Nangang!',
                no_balance: 'No Points, Press $ button or Contact LEAP@LINE'
            },
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                orders: 'Orders',
                balance: 'Balance',
                groups: 'Segments',
                last_unlock_datetime: 'Last Unlocked',
                name: 'Name',
                acc_mins: 'Used Mins',
            },
            tabs: {
                usage: 'Usage',
                identity: 'Identity',
                address: 'Address',
                orders: 'Orders',
                reviews: 'Reviews',
                class: 'Class',
                status: 'Status',
                payment: 'Payment'
            },
            page: {
                delete: 'Delete Customer',
            },
        },
        orders: {
            name: 'Order',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        products: {
            name: 'Class |||| Classes',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
                ig_html: 'IG HTML',
            },
        },
        events: {
            name: 'Event |||| Events',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        trainers: {
            name: 'Trainer Name',
            summary: 'Summary',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Avatar',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
                ig_html: 'IG HTML',
                social_link: 'Social Link',
            },
        },
        myevents: {
          name: 'My Event |||| My Events'
        },
        terms: {
          name: 'Terms and Conditions'
        },
        profile:{
          name: 'My Profile',
          datetime_left: 'Departure',
          mins_used: 'Mins Used',
          type_used: 'Usage'
        },
        bookings:{
          name: 'BOOKING',
        }
        ,
        gyms:{
          name: 'GYM',
          location: 'LOCATION',
        }
        ,
        plans:{
          name: 'Buy Plans',
        }
    },
};
