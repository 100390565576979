import React from 'react';
import {
    Edit,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
    LongTextInput,
    FormDataConsumer,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';

import { styles as createStyles } from './PlanCreate';

const PlanTitle = ({ record }) => <span>Poster #{record.reference}</span>;

const styles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const PlanEdit = ({ classes, ...props }) => (
    <Edit {...props} title={<PlanTitle />}>
        <TabbedForm>
          <FormTab label="resources.plans.tabs.details" path="details">
            <TextInput source="title" validate={required()} />
            <TextInput source="subheader" validate={required()} />
            <SelectInput
                source="unit"
                validate={required()}
                defaultValue={'30'}
                choices={[
                  { id: 'TWD', name: 'TWD' },
                  { id: 'USD', name: 'USD' },
                  { id: 'points', name: 'points' },
                  { id: 'others', name: 'others' },
                ]}
            />
            <FormDataConsumer>
               {({ formData, ...rest }) => formData.unit &&
                 <NumberInput source="price" validate={required()} />
               }
            </FormDataConsumer>
            <NumberInput source="point" />
            <SelectInput
                source="period"
                defaultValue={'30'}
                choices={[
                  { id: '30', name: '30' },
                  { id: '90', name: '90' },
                  { id: '180', name: '180' },
                  { id: '365', name: '365' },
                ]}
            />
            <NumberInput source="free_gct"  />
          </FormTab>
          <FormTab
              label="resources.plans.tabs.description"
              path="description" >
            <RichTextInput
              toolbar={[
                ['bold', 'italic', 'underline', 'link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'align': [] }], ]}
              source="description" />
          </FormTab>
        </TabbedForm>
    </Edit>
);

export default withStyles(styles)(PlanEdit);
