import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, ReferenceField, SearchInput, Filter } from 'react-admin';
import moment from 'moment-timezone'
//import EventListActions from './EventListActions'

const now_MM = moment(new Date()).tz("Asia/Taipei").format("YYYY-MM")

const ResvFilters = props => (
    <Filter {...props}>
        <SearchInput label="UID" source="userId" alwaysOn />
        <SearchInput label="GYM" source="gymId" alwaysOn />
    </Filter>
);

const EventList = ({ classes, permissions, ...props }) => (
    <List title="Events" {...props}
      filters={<ResvFilters />}
      sort={{ field: 'resTimestamp', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      perPage={50}
      >
        <Datagrid>
          <DateField type="datetime-local" label="Date" source="resTimestamp" showTime locales="zh-TW"
            options={ {month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} }/>
            <TextField label="Type"source="resType" />
            <TextField label="Gym" source="gymId" />
            {permissions === 'leaper' &&
              <ReferenceField label="Name" source="userId" reference="customers">
                <TextField source="username" />
              </ReferenceField>
            }
            {permissions === 'leaper' &&
              <TextField label="UID" source="userId" />
            }
            <TextField source="status" />
            {permissions === 'leaper' && <EditButton />
            }
        </Datagrid>
    </List>
);

export default EventList;
