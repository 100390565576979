
import React from 'react';
import {

    Datagrid,
    DateField,
    DateInput,
    TextField,
    EditButton,
    Filter,
    List,
    BooleanField,
    NumberField,
    Responsive,
    SearchInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import CustomerLinkField from './CustomerLinkField';
import MobileGrid from './MobileGrid';

const CustomerFilter = props => (
    <Filter {...props}>
        <SearchInput source="username" alwaysOn />
        <SearchInput source="line_name" />
        <SearchInput source="email" />
        <DateInput source="last_unlock_datetime" />
    </Filter>
);

const listStyles = {
    nb_commands: { color: 'purple' },
};

const CustomerList = ({ classes, ...props }) => (
    <List
      {...props}
      filters={<CustomerFilter />}
      sort={{ field: 'checked', order: "ASC" }}
      perPage={40}
    >
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <BooleanField source="checked" />
                    <BooleanField source="gctChecked" />
                    <BooleanField source="yogaChecked" />
                    <BooleanField source="gptChecked" />
                    <BooleanField source="ptChecked" />
                    <BooleanField source="ptv2Checked" />
                    <BooleanField source="rptChecked" />
                    <CustomerLinkField />
                    <TextField source="line_name" />
                    <NumberField source="balance" />
                    <NumberField source="free_gct" />
                    <NumberField source="acc_mins" />
                    <DateField source="last_unlock_datetime" showTime />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(listStyles)(CustomerList);
