import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { NumberField, TextField, DateField, ReferenceField, EditButton } from 'react-admin';
import { linkToRecord } from 'ra-core';
import {Card, CardHeader, CardMedia, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


import Avatar from '@material-ui/core/Avatar'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'


const styles = theme => ({
    root: {
      display: 'block',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    link: {
        color: '#fff',
    },
    details: {
      height: 140,
    },
    content: {
      flex: '1 0 auto',
    },
    card: {
      padding: 0,
    },
    media: {
      height:'100%',
      width: '100%',
      maxWidth: '200px',
      verticalAlign: 'middle'
    },
    iconColumns:{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'right',
      width: '100%',
      textAlign: 'right',
    },
    typo25:{
      fontSize: '1.3em',
      fontWeight: '25',
      width: '100%',
    },
    typo75:{
      fontSize: '1.3em',
      fontWeight: '75',
      width: '100%',
    },
    icon:{
      marginRight: '0.5em',
      width: '1em',
      height: '1em',
    },
});

const getColsForWidth = width => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 2;
    if (width === 'lg') return 3;
    return 3;
};

const getHeightForGrid = width => {
    if (width === 'xs') return 384;
    if (width === 'sm') return 384;
    if (width === 'md') return 512;
    if (width === 'lg') return 640;
    return 640;
};

const getHeightForImg = width => {
    if (width === 'xs') return 256;
    if (width === 'sm') return 256;
    if (width === 'md') return 384;
    if (width === 'lg') return 512;
    return 512;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const LoadedGridList = ({ classes, ids, data, basePath, width }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={getHeightForGrid(width)}
            cols={getColsForWidth(width)}
            spacing={5}
        >
            {ids.map(id => (
              <GridListTile key={id} style={{ height: '100%' }} component={Link}
              style={{ textDecoration: 'none' }}
               to={`${linkToRecord(basePath, data[id].id)}/show`}>
                   <CardMedia
                     style={{height:getHeightForImg(width), objectFit: 'contain'}}
                     image={data[id].cover[0]?data[id].cover[0].src:data[id].cover.src}
                     title="cover"
                   />
                   <div style={{margin:8}}>
                     <Typography color="textPrimary" className={classes.typo75}>
                        {data[id].title}
                     </Typography>
                     <Typography color="textSecondary" >
                        {data[id].subheader}
                     </Typography>
                     <div className={classes.iconColumns}>
                       <Typography color="primary" className={classes.typo25}>
                          <FontAwesomeIcon icon={faDollarSign} className={classes.icon}/>{data[id].price}
                       </Typography>
                     </div>
                   </div>
                   <Divider />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const PlanGrid = ({ loadedOnce, ...props }) =>
    loadedOnce ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

const enhance = compose(
    withWidth(),
    withStyles(styles)
);

export default enhance(PlanGrid);
