import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import MainUserMenu from './MainUserMenu';
import LogoImage from '../assets/logo44h.png';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    appbar:{
      background:"rgba(255,62,36,0.8)"
    },
};



const MainAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar {...props} userMenu={<MainUserMenu />}
    title={''}
    className={classes.appbar} >
    <img src={LogoImage}
     style={{objectFit:'scale-down', height:32, flex:1, paddingLeft:48 }} alt=""/>
  </AppBar>
));

export default MainAppBar;
