import React, { useState, useCallback, useEffect  } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider';
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'
import PayIcon from '@material-ui/icons/Payment';
import DirectionsIcon from '@material-ui/icons/Directions'
import {
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    NumberInput,
    ImageField,
    CardActions,
    EditButton,
    DeleteButton,
    RichTextField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import firebaseCloudFunc from '../layout/firebaseCloudFunc';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {PAYMENT_PROVIDERS, COUNTRY_CODES, AMOUNTS} from '../config'
import { showNotification, translate } from 'react-admin';

import CreatePlanInfoCol from '../layout/CreatePlanInfoCol'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  showlayout:{
    display: 'block',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    textAlign: 'center',
  },
  flexContainer:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 'auto',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
  },
  name:{
    fontSize: '1.3em',
  },
  title:{
    fontSize: '1em',
    margin: '0 0 1em 0',
  },
  iconColumns:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    margin: '0.3em',
  },
  typo:{
    fontSize: '1.3em',
    fontWeight: '50',
    width: '100%',
  },
  icon:{
    marginRight: '0.5em',
    width: '1em',
    height: '1em',
  },
  imageIcon: {
    height: '1em'
  },
  iconRoot: {
    textAlign: 'center'
  },
  desc:{
    textAlign: 'left',
    fontSize: '1.1em',
    marginRight: '10px',
    marginLeft: '10px',
  }
})

const PlanShowActions = ({ permissions, basePath, data, resource }) => (
    <CardActions style={{index:-2}}>
        {permissions === 'leaper' &&
            <EditButton basePath={basePath} record={data} />
          }
        {permissions === 'leaper' &&
            <DeleteButton basePath={basePath} record={data} resource={resource} />
        }
    </CardActions>
);


const PlanTitle = ({ record }) => {
    return <span>{record ? `${record.title}` : ''}</span>;
};


function PlanShow ({ classes, permissions, translate, ...props }){
  const [apiRes, setApiRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [checkedRcv, setCheckedRcv] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const [amount, setAmount] = React.useState(1);
  const [result, setResult] = useState(null);
  const [payBtnText, setpayBtnText] = useState('pos.checkout');
  const [dryRun, setDryrun] = useState(false);
  const [user, setUser] = useState(false);
  const [nowRec, setNowRec] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseToLogin = () => {
    setOpen(false);
    document.location.assign("#/login");
  };

  const ShowCover = ({ record, source }) => (
    <Carousel showThumbs={false} autoPlay>
      {
        record[source].map(
          item =>(
            <div>
                <img src={item.src} />

                <p className="legend" hidden={item.title?false:true}>{item.title?item.title:''}</p>
            </div>
          )
        )
      }
    </Carousel>
  )


  const [rcvCountry, setRcvCountry] = React.useState("TW");
  const handleRcvCountry =useCallback((evt,price)=>{
    setRcvCountry(evt.target.value)
    console.log('setRcvCountry:' + evt.target.value)
    if(evt.target.value!="TW"){
      setTotal(amount*price+300)
    }else{
      setTotal(amount*price)
    }
  })

  const [rcvName, setRcvName] = React.useState(null);
  const onNameChange=useCallback(evt=>{
    setRcvName(evt.target.value)
    console.log('setRcvName:' + evt.target.value)
  })

  const [rcvPhone, setRcvPhone] = React.useState(null);
  const [rcvZIP, setRcvZIP] = React.useState(null);
  const [rcvAddr, setRcvAddr] = React.useState(null);
  const handleRcvPhone = (evt)=>setRcvPhone(evt.target.value)



  const handleRcvZIP = useCallback(evt=>{
    setRcvZIP(evt.target.value)
  })
  const handleRcvAddr = useCallback(evt=>{
    setRcvAddr(evt.target.value)
    console.log('addr:' + rcvAddr)
  })



  const CreateCheckoutButton = ({ record, btnLabel, amtLabel, checkLabel, countryLabel }) => {
    useEffect(() => {
      firebase.auth().onAuthStateChanged(function(user) {
        if(user){
          setUser(user)
        }else{
          console.log('not logged in')
        }
      })
    })

    const handleChecked = useCallback(event => {
      setChecked(event.target.checked);
      //setNowRec(record)
      //setTotal(amount*record.price)

      if(rcvCountry!="TW"){
        setTotal(total+300)
      }
      console.log('total:' + total)
      if(user&&total&&rcvName){
        setLoading(true)
        var total2 = total
        console.log('rcvCountry:' + rcvCountry)
        if(rcvCountry!="TW"){
          total2+=300
        }
        var getPayformApi = firebase.functions().httpsCallable('getPayform')
        const res = getPayformApi(
          {dryrun: dryRun,
           productId:record.id,
           amt:total2,
           title:record.title}).
           then(function(result){
             setApiRes(result.data.model)
             var apiFunc = firebase.functions().httpsCallable('checkout2');
             console.log(
               'payModel:' + result.data.model +
               ',orderId?' + result.data.newOrderId+
               ',rcvName?' + rcvName +
               ',rcvCountry?' + rcvCountry +
               ',rcvAddr?' + rcvAddr +
               ',rcvTotal?' + total2 +
               ',rcvAmt?' + amount  )
             if(result.data.newOrderId){
               apiFunc({
                 orderId: result.data.newOrderId,
                 productId: record.id,
                 rcvName: rcvName,
                 rcvPhone: rcvPhone,
                 rcvCountry: rcvCountry,
                 rcvZIP: rcvZIP,
                 rcvAddr: rcvAddr,
                 rcvAmt: amount,
                 rcvTotal: total2}).then(function(order_result) {
                 setResult(order_result)
                 setLoading(false)
               }).catch(function(error){
                 console.log('error:' + error)
               })
             }else{
               console.log('orderId is error')
               setLoading(true)
             }
           })
      }else if(!user){
        console.log('not logged in')
        setChecked(false)
        setOpen(true);
      }
    }, [record]);

    const handleCheckedRcv = useCallback(evt=>{
      if(user){
        setCheckedRcv(evt.target.checked)
        setTotal(amount*record.price)
        console.log('setRecId:' + record.id)
      }else{
        console.log('not logged in')
        setChecked(false)
        setOpen(true);
      }
    })

    const handleAmount = useCallback(evt=>{
      setAmount(evt.target.value)
      setTotal(evt.target.value*record.price)
      console.log('amount:' + amount)
    })

    const handleInsideChecked = useCallback(event => {
      setChecked(event.target.checked);

      if(user&&record){
        setLoading(true)
        var getPayformApi = firebase.functions().httpsCallable('getPayform')
        const res = getPayformApi(
          {dryrun: dryRun,
           productId:record.id,
           amt:record.price,
           title:record.title}).
           then(function(result){
             setApiRes(result.data.model)
             var apiFunc = firebase.functions().httpsCallable('checkout2');
             console.log(
               'payModel:' + result.data.model +
               ',orderId?' + result.data.newOrderId)
             if(result.data.newOrderId){
               apiFunc({
                 orderId: result.data.newOrderId,
                 productId: record.id}).then(function(order_result) {
                 setResult(order_result)
                 setLoading(false)
               }).catch(function(error){
                 console.log('error:' + error)
               })
             }else{
               console.log('orderId is error')
               setLoading(true)
             }
           })
      }else if(!user){
        console.log('not logged in')
        setChecked(false)
        setOpen(true);
      }
    }, [record]);

    return(
      <div style={{
        position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
        padding: 6,
        backgroundColor: '#efefef',
        textAlign: 'center',
      }}>
      {!record.point?(<FormControl>
        <InputLabel style={{fontSize: 18}}>{amtLabel}*</InputLabel>
        <NativeSelect
          value={amount}
          onChange={handleAmount}
          inputProps={{
            name: 'amount',style: {fontSize: 16}
          }}
          style = {{width: 64, marginRight:10}}
          fullWidth
          disabled={checked}
        >
          {AMOUNTS.map(amount=>(
              <option value={amount.amt}>{amount.label}</option>
            )
          )}
        </NativeSelect>
      </FormControl>):('')}
      {!record.point?(<FormControl>
        <InputLabel style={{fontSize: 18}}>{countryLabel}*</InputLabel>
        <NativeSelect
          value={rcvCountry}
          onChange={event=>handleRcvCountry(event,record.price)}
          inputProps={{
            name: 'rcvCountry',style: {fontSize: 16}
          }}
          fullWidth
          required={true}
          disabled={checked}
        >
          {COUNTRY_CODES.map(country=>(
              <option value={country.code}>{country.cn}</option>
            )
          )}
        </NativeSelect>
      </FormControl>):('')}

      {
        checkedRcv?(
          <Card style={{
            margin:10,
            position: 'fixed', right: 0, top: '25%', left: 0, zIndex: 100,
            padding: 6,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            textAlign: 'center',
          }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                {translate('pos.rcvInfo')}
              </Typography>
              <TextField
                label={translate('pos.rcvName')}
                value={rcvName}
                type="text"
                InputLabelProps={{
                  shrink: true,
                  style: {fontSize: 18, fontWeight:'bold'}
                }}
                InputProps={{name: 'rcvName', style: {fontSize: 18, fontWeight:'bold'}}}
                fullWidth
                required={true}
                onBlur={onNameChange}
              />
              <TextField
                label={translate('pos.rcvPhone')}
                type="text"
                value={rcvPhone}
                InputLabelProps={{
                  shrink: true,
                  style: {fontSize: 18, fontWeight:'bold'}
                }}
                InputProps={{style: {fontSize: 18, fontWeight:'bold'}}}
                fullWidth
                required={true}
                onBlur={handleRcvPhone}
              />
              <TextField
                label={translate('pos.rcvZIP')}
                type="text"
                value={rcvZIP}
                InputLabelProps={{
                  shrink: true,
                  style: {fontSize: 18, fontWeight:'bold'}
                }}
                InputProps={{style: {fontSize: 18, fontWeight:'bold'}}}
                fullWidth
                required={true}
                onBlur={handleRcvZIP}
              />
              <TextField
                label={translate('pos.rcvAddr')}
                type="text"
                value={rcvAddr}
                InputLabelProps={{
                  shrink: true,
                  style: {fontSize: 18, fontWeight:'bold'}
                }}
                InputProps={{style: {fontSize: 18, fontWeight:'bold'}}}
                required={true}
                fullWidth
                onBlur={handleRcvAddr}
              />
              <FormControlLabel style = {{marginLeft:6}}
                  control={
                    <Checkbox checked={checked} onChange={handleChecked}
                    disabled={!rcvName||!rcvZIP||!rcvAddr||!rcvPhone||!rcvCountry} />
                  }
                  label={translate('pos.rcvHint')}
              />
            </CardContent>
          </Card>
        ):('')
      }

        <FormControlLabel style = {{marginLeft:4}}
            control={
              record.point?(
                <Checkbox checked={checked} onChange={handleInsideChecked} />
              ):(
                <Checkbox checked={checkedRcv} onChange={handleCheckedRcv} />
              )

            }
            label={checkLabel}
        />
        <Button fullWidth
          color='primary'
          variant='raised'
          type="submit"
          form={dryRun?PAYMENT_PROVIDERS.cnewebPay.name:PAYMENT_PROVIDERS.newebPay.name}
          key={dryRun?PAYMENT_PROVIDERS.cnewebPay.name:PAYMENT_PROVIDERS.newebPay.name}
          disabled={loading||!checked||((!rcvName||!rcvAddr||!rcvZIP||!rcvPhone)&&record.point==null)}>
            {loading?(
              <CircularProgress
                  size={25}
                  thickness={2}
                  style={{'marginRight': '0.5em'}}
              />
            ):(
              <PayIcon style={{'marginRight': '0.5em'}}/>
            )}{btnLabel} TWD${total?total:record.price}
        </Button>
      </div>
    )
  }
/*
||((!rcvName||!rcvAddr||!rcvZIP||!rcvPhone)&&record.point==null)
*/
  // setup render helper
  function renderRes([key, value]) {
    return (
      <input type="hidden" name={key} value={value} key={key} />
    );
  }

  return(
    <Show title={<PlanTitle />} actions={<PlanShowActions permissions={permissions} />} {...props}
     >
      <SimpleShowLayout className={classes.showlayout}>
        <ShowCover source="cover" />
        <CreatePlanInfoCol
          className={classes.iconColumns}
          typoClassName={classes.typo}
          iconClassName={classes.icon}/>
        <RichTextField source="description" addLabel={false} className={classes.desc} />
        <form id={dryRun?PAYMENT_PROVIDERS.cnewebPay.name:PAYMENT_PROVIDERS.newebPay.name}
          method="post" action={dryRun?PAYMENT_PROVIDERS.cnewebPay.apiUrl:PAYMENT_PROVIDERS.newebPay.apiUrl}>
          {apiRes && Object.entries(apiRes).map(renderRes)}
        </form>
        <CreateCheckoutButton
          checkLabel={translate('pos.confirm')}
          btnLabel={translate('pos.payOnline')}
          amtLabel={translate('pos.amount')}
          countryLabel={translate('pos.country')} />

        <Dialog open={open}
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{translate('pos.needLogin')}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {translate('pos.nowNow')}
            </Button>
            <Button onClick={handleCloseToLogin} color="primary" autoFocus>
              {translate('pos.login')}
            </Button>
          </DialogActions>
        </Dialog>
      </SimpleShowLayout>
    </Show>
  );
}


export default withStyles(styles)(translate(PlanShow));
