import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import DataProvider from '../dataProviders'
import moment from 'moment-timezone'
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_ONE } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'

const styles = theme => ({
});

const dataProvider = DataProvider();

const FirebaseDialog = ({isLoading, translate, currentLoc}) => {
    const[open, setOpen] = useState(true)
    const[content, setContent] = useState('');
    const[title, setTitle] = useState('');
    useEffect(() => {
      dataProvider(GET_ONE, 'gyms', {id:currentLoc} )
      .then(response => {
        if(response.data && response.data.dashboard){
          if(response.data.dashboard.dialogTitle){
            setTitle( response.data.dashboard.dialogTitle)
          }

          if(response.data.dashboard.dialogContent){
            setContent( response.data.dashboard.dialogContent)
          }
        }else{
          console.log('no dashboard dialog msg.')
        }
      }).catch( err => {
        console.log('get gyms error: ' + err)
      })
    })
    const handleClose = () => {
      setOpen(false)
    }

    const handleCloseToExit = () => {
      setOpen(false)
    }

    return(
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{isLoading?'':title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isLoading?<CircularProgress />:content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading?'':<Button onClick={handleCloseToExit} color="primary" autoFocus>
            {translate('pos.agree')}
          </Button>}

        </DialogActions>
      </Dialog>
    )
  }

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(FirebaseDialog);
