export const GYM_NGS_ID='id20190722ngs'
export const GYM_ZXFS_ID='id20191209zxfs'
export const GYM_KYS_ID='id20210401kys'
export const ZFS_LOC = {lat:25.042137, log:121.539102}
export const NGS_LOC = {lat:25.054275, log:121.606015}
export const KYS_LOC = {lat:25.0469249, log:121.5817588}
//export const KYS_LOC = {lat:25.0530691, log:121.6069956} //intel
export const API_URL = 'https://us-central1-leapfitz.cloudfunctions.net'
export const PAYMENT_PROVIDERS = {
  newebPay:{name:'newebPay',apiUrl:'https://core.spgateway.com/MPG/mpg_gateway', title:'pos.payOnline'},
  cnewebPay:{name:'cnewebPay',apiUrl:'https://ccore.spgateway.com/MPG/mpg_gateway', title:'pos.payOnline'},
  cash:{apiUrl:'', title:'pos.payOnthego'}
}

export const AMOUNTS = [
  {"amt":1, "label":"一"},
  {"amt":2, "label":"二"},
  {"amt":3, "label":"三"},
  {"amt":4, "label":"四"},
  {"amt":5, "label":"五"},
  {"amt":6, "label":"六"},
  {"amt":7, "label":"七"},
  {"amt":8, "label":"八"},
  {"amt":9, "label":"九"},
  {"amt":10, "label":"十"},
  {"amt":11, "label":"十一"},
  {"amt":12, "label":"十二"},
  {"amt":13, "label":"十三"},
  {"amt":14, "label":"十四"},
  {"amt":15, "label":"十五"},
]


// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const COUNTRY_CODES = [
{"code": "TW", "en": "Taiwan", "cn": "臺灣"},
{"code": "HK", "en": "Hong Kong", "cn": "香港 (運費+300)"},
{"code": "MO", "en": "Macao", "cn": "澳門 (運費+300)"},
{"code": "US", "en": "United States of America (USA)", "cn": "美國  (運費+300)"},
{"code": "CA", "en": "Canada", "cn": "加拿大  (運費+300)"},
{"code": "CN", "en": "China", "cn": "中國  (運費+300)"},
{"code": "DE", "en": "Germany", "cn": "德國 (運費+300)"},
{"code": "FR", "en": "France", "cn": "法國 (運費+300)"},
{"code": "GB", "en": "Great Britain (United Kingdom; England)", "cn": "英國 (運費+300)"},
{"code": "MX", "en": "Mexico", "cn": "墨西哥 (運費+300)"},
{"code": "MY", "en": "Malaysia", "cn": "馬來西亞 (運費+300)"},
{"code": "NZ", "en": "New Zealand", "cn": "紐西蘭 (運費+300)"},
{"code": "SG", "en": "Singapore", "cn": "新加坡 (運費+300)"},
{"code": "TH", "en": "Thailand", "cn": "泰國 (運費+300)"},
]
