import React from 'react';
import {
    Create,
    FormTab,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ImageInput,
    ImageField,
    ReferenceInput,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';
import TrainerSelectInput from '../trainers/TrainerSelectInput'

export const styles = {
    stock: { width: '5em' },
    price: { width: '5em' },
    width: { width: '5em' },
    widthFormGroup: { display: 'inline-block' },
    height: { width: '5em' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const ProductCreate = ({ classes, ...props }) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="resources.products.tabs.details">
              <TextInput source="class_name" validate={required()} />
              <NumberInput
                  source="price"
                  validate={required()}
              />
              <SelectInput
                  source="price_unit"
                  validate={required()}
                  defaultValue={'point'}
                  choices={[
                    { id: 'ntd', name: 'NTD' },
                    { id: 'point', name: 'point' },
                    { id: 'usd', name: 'USD' },
                  ]}
              />
              <NumberInput
                  source="length"
                  validate={required()}
                  defaultValue={60}
              />
              <NumberInput
                  source="series_amount"
                  validate={required()}
                  defaultValue={1}
              />
              <NumberInput
                  source="seat_ceil"
                  validate={required()}
                  defaultValue={6}
              />
              <NumberInput
                  source="seat_floor"
                  validate={required()}
                  defaultValue={4}
              />
              <ReferenceInput source="location_id" reference="gyms">
                <SelectInput optionText="location" />
              </ReferenceInput>
            </FormTab>
            <FormTab
                label="resources.products.tabs.description"
                path="description"
            >
                <RichTextInput source="description" addLabel={false} />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default withStyles(styles)(ProductCreate);
