import React, { Component } from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import authProvider from './authProvider';
//import authClient from './authClient';
import Dashboard from './dashboard/Dashboard';
import Login from './Login';
import terms from './terms';
//import gcalDataProvider from './gcalDataProvider';
import englishMessages from './i18n/en';
import zhtwMessages from './i18n/tw';
//import restClient from './firebase-client/restClient';
import DataProvider from './dataProviders'
import customers from './customers';
import orders from './orders';
import Menu from './Menu';
import customRoutes from './customRoutes';
import products from './products';
import trainers from './trainers';
import plans from './plans';
import GiftList from './plans/GiftList'
import PlanShow from './plans/PlanShow'
import PlanEdit from './plans/PlanEdit'
import bookings from './bookings';
import events from './events';
import trackings from './trackings';
import { createMuiTheme } from '@material-ui/core/styles';
import yy_orange from './styles/yy_orange'
import MainLayout from './layout/MainLayout';
import ProfileShow from './profile/ProfileShow';

const messages = {
    tw: zhtwMessages,
    en: englishMessages,
}

const i18nProvider = locale => messages[locale];

const fontWeightMedium = 500;

const muiTheme=createMuiTheme({
  palette: {
    primary: yy_orange,
    secondary: yy_orange,
    fontFamily: '-apple-system,system-ui, "Microsoft JhengHei", BlinkMacSystemFont,' +
      '"Segoe UI","Noto Sans TC",Lato,"Helvetica Neue",Arial,sans-serif',
  },
  typography: {
    fontFamily:'-apple-system,system-ui, "Microsoft JhengHei", BlinkMacSystemFont,' +
      '"Segoe UI","Noto Sans TC",Lato,"Helvetica Neue",Arial,sans-serif',
    fontWeightMedium,
    body1: {
       fontWeight: fontWeightMedium,
     },
  },
  overrides: {
   MuiTextField:{
     root:{
       height: 42,
     }
   },
   MuiInput:{
     root:{
       fontSize: '1.6rem',
     },
   },
   MuiInputLabel:{
     root:{
       fontSize: '1.6rem',
     },
   }
 },
});

class App extends Component {
  state = { dataProvider: null };

  async componentWillMount() {
    //const dataProvider = await restClient(clientOptions.trackedResources, clientOptions);
    const dataProvider = await DataProvider();
    //const uploadCapableDataProvider = addUploadFeature(dataProvider);
    this.setState({ dataProvider });
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
      <Admin
        theme={muiTheme}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={Login}
        locale="tw"
        i18nProvider={i18nProvider}
        menu={Menu}
        customRoutes={customRoutes}
        appLayout={MainLayout}
        >
        {permissions => [
          <Resource name="trainers" {...trainers} />,
          <Resource name="products" {...products}/>,
          <Resource name="mydata" {...products}/>,
          <Resource name="terms" {...terms}/>,
          permissions === "leaper" ? <Resource
            name="terms"
            {...terms}
          /> : null,
          permissions === "leaper" ? <Resource
            name="customers"
            {...customers}
          /> : null,
          permissions === "leaper" ? <Resource
            name="reservations"
            {...events}
          /> : <Resource
            name="myreservations"
            {...events}
          />
        ]}
      </Admin>
    );
  }
}

export default App;
