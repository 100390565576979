import React from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  DateInput,
  ArrayField,
  Datagrid,
  TextField,
  NumberField,
  Edit,
  FormTab,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import withStyles from '@material-ui/core/styles/withStyles';
import FullNameField from './FullNameField';
import { styles } from './CustomerCreate';
import moment from 'moment-timezone'

const CustomerTitle = ({ record }) =>
    record ? <FullNameField record={record} size={32} /> : null;

const checked_choices = [
  { id: true, name: 'In Gym' },
  { id: false, name: 'Out of Gym' },
]

const vendor_choices = [
  { id: 'newebpay', name: 'NewebPay' },
  { id: 'ezpay', name: 'EzPay' },
  { id: 'transfer', name: 'Transfer' },
  { id: 'cash', name: 'Cash' },
]

const CustomerEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const dateParser = v => {
  const timezone_RFC = moment.tz(v, "Asia/Taipei").format()
  return timezone_RFC
}

const dateFormater = v => {
  const datetime_RFC3339= moment(v).tz("Asia/Taipei").format(moment.HTML5_FMT.DATETIME_LOCAL)
  return datetime_RFC3339
}

const CustomerEdit = ({ classes, ...props }) => (
    <Edit title={<CustomerTitle />} {...props}>
      <TabbedForm  toolbar={<CustomerEditToolbar />}>
          <FormTab label="resources.customers.tabs.identity">
              <TextInput
                  source="username"
                  formClassName={classes.inline}
                  readOnly
              />
              <TextInput source="line_name" formClassName={classes.inline} />
              <TextInput source="chinese_fullName" formClassName={classes.inline} />
              <TextInput
                  type="email"
                  source="email"
                  validation={{ email: true }}
                  fullWidth={true}
                  formClassName={classes.email}
                  readOnly
              />
          </FormTab>
          <FormTab label="resources.customers.tabs.status" path="status">
            <NumberInput source="balance" formClassName={classes.inline} />
            <NumberInput source="acc_mins" formClassName={classes.inline} />
            <NumberInput source="free_gct" formClassName={classes.inline} />
            <SelectInput source="checked" choices={checked_choices} />
            <DateInput source="last_unlock_datetime" showtime />
            <BooleanInput source="gptChecked"  />
            <BooleanInput source="gctChecked"  />
            <BooleanInput source="yogaChecked"  />
            <BooleanInput source="ptChecked"  />
            <BooleanInput source="rptChecked"  />
            <BooleanInput source="ptv2Checked" />
          </FormTab>
          <FormTab label="resources.customers.tabs.payment" path="order">
            <ArrayInput source="orders">
              <SimpleFormIterator>
                  <DateInput source="date" />
                  <SelectInput source="vendor" choices={vendor_choices} />
                  <TextInput source="serial" />
                  <NumberInput source="amounts" />
                  <NumberInput source="points" />
                  <NumberInput source="free_gct" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="resources.customers.tabs.usage_edit" path="usage_edit">
            <ArrayInput source="usage" fullWidth>
                <SimpleFormIterator>
                  <TextInput fullWidth
                    type="datetime-local"
                    source="datetime"
                    label="離場時間"/>
                  <SelectInput fullWidth source="usageType" label="類型" choices={[
                        { id: 'WORKOUT', name: 'WORKOUT' },
                        { id: 'GCT', name: 'GCT' },
                        { id: 'RPT', name: 'RPT' },
                        { id: 'PT (首堂)', name: 'PT (首堂)' },
                        { id: 'PT', name: 'PT' },
                        { id: '1v2', name: '1v2' },
                        { id: 'YOGA', name: 'YOGA' },
                    ]} />
                  <NumberInput fullWidth source="length" label="使用時間"/>
                  <RichTextInput
                    label="備註"
                    toolbar={[
                      ['bold', 'italic', 'underline', 'link'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'align': [] }], ]}
                    source="comment" />
                  {/*
                  <NumberInput fullWidth source="oldBalance" label="上次點數餘額"/>
                  <NumberInput fullWidth source="newBalance" label="本次點數餘額"/>
                  */}
                </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
      </TabbedForm>
    </Edit>
);

export default withStyles(styles)(CustomerEdit);
