import React from 'react';

import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParking, faDollarSign, faHourglassStart } from '@fortawesome/free-solid-svg-icons'


const CreatePlanInfoCol = ({ record, className, typoClassName, iconClassName, ...rest }) => {
  return(
    <div style={{width:'100%'}}>
      {record.point?(
        <div className={className}>
          <Typography color="textPrimary" className={typoClassName}>
              <FontAwesomeIcon icon={faDollarSign} className={iconClassName} />
              {record.price}
          </Typography>
          <Typography color="textPrimary" className={typoClassName}>
              <FontAwesomeIcon icon={faParking} className={iconClassName} />
              {record.point}
          </Typography>
          <Typography color="textPrimary" className={typoClassName}>
              <FontAwesomeIcon icon={faHourglassStart} className={iconClassName} />
              {record.period}
          </Typography>
          </div>
        ):(<br />)}
      </div>

  )
}



export default CreatePlanInfoCol
