import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider';
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'
import DirectionsIcon from '@material-ui/icons/Directions'
import {
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    ImageField,
    CardActions,
    EditButton,
    DeleteButton,
    RichTextField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { CreateButton } from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const styles = theme => ({
  showlayout:{
    display: 'block',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    textAlign: 'center',
  },
  flexContainer:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 'auto',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
  },
  iconStyle:{
    fontSize: '1em',
    padding: '0 0.2em 0 0',
    color: 'grey',
    textAlign: 'left',
  },
  rightPadding:{
    padding: '0 1em 0 0',
    color: 'grey',
    fontSize: '1em',
    textAlign: 'center',
  },
  name:{
    fontSize: '1.3em',
  },
  title:{
    fontSize: '1em',
    margin: '0 0 1em 0',
  },
  class_name:{
    fontSize: '1.3em',
    margin: '1em 0 0 0',
  },
  divider:{
    margin: '0.5em 0 0.5em 0'
  },
  imageIcon: {
    height: '1em'
  },
  iconRoot: {
    textAlign: 'center'
  }
})

const TrainerShowActions = ({ permissions, basePath, data, resource }) => (
    <CardActions style={{index:-2}}>
        {permissions === 'leaper' &&
            <EditButton basePath={basePath} record={data} />
          }
        {permissions === 'leaper' &&
            <DeleteButton basePath={basePath} record={data} resource={resource} />
        }
    </CardActions>
);

const TrainerTitle = ({ record }) => {
    return <span>{record ? `${record.trainer_name}` : ''}</span>;
};

const ShowAvatar = ({ record, source }) => {
  return (
    <Avatar
      src={record[source][0].src}
      style={{width:100,height:100,display: 'block',margin: 'auto'}}
    />
  );
};

const CreateRelatedComment = ({ record }) => (
  <Button
      label="JOIN"
      href="https://line.me/R/ti/p/%40qpx8269n"
  >
      +LINE
  </Button>
);

const CreateSocialIconButtons = ({ record, inner_classes }) => {
  return(
    <div className={inner_classes.flexContainer}>
    {record.fb_link && <IconButton
        aria-label="facebook"
        href={record.fb_link}
    >
      <Icon className={inner_classes.iconRoot}>
        <img className={inner_classes.imageIcon} src="/svgs/facebook-square.svg"/>
      </Icon>
    </IconButton>}
    {record.ig_link && <IconButton
        aria-label="instagram"
        href={record.ig_link}
    >
      <Icon className={inner_classes.iconRoot}>
        <img className={inner_classes.imageIcon} src="/svgs/instagram.svg"/>
      </Icon>
    </IconButton>}
    </div>
  )
}


const TrainerShow = ({ classes, permissions, ...props }) => (
    <Show title={<TrainerTitle />} actions={<TrainerShowActions permissions={permissions} />} {...props}
     className={classes.showlayout}>
        <SimpleShowLayout className={classes.showlayout}>
            <CreateRelatedComment />
            <ShowAvatar source="avatar" />
            <TextField color="primary" source="trainer_name" addLabel={false} className={classes.name}/>
            <TextField source="trainer_title" addLabel={false} className={classes.title}/>
            <CreateSocialIconButtons inner_classes={classes} />
            <TextField color="primary" source="trainer_slogan" addLabel={false} className={classes.class_name}/>
            <Divider variant="middle" className={classes.divider}/>
            <RichTextField source="description" addLabel={false} />
        </SimpleShowLayout>
    </Show>
);

export default withStyles(styles)(TrainerShow);
