import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider';
import MoneyIcon from '@material-ui/icons/AttachMoney'
import TimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/PersonOutline'
import DirectionsIcon from '@material-ui/icons/Directions'
import {
    DateField,
    ReferenceField,
    SimpleShowLayout,
    Show,
    TextField,
    ImageField,
    CardActions,
    EditButton,
    DeleteButton,
    RichTextField,
    NumberField,
    ArrayField,
    Datagrid,
    DatagridBody,
    DatagridRow,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import Button from '@material-ui/core/Button';
import { CreateButton } from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { translate } from 'react-admin';

const styles = theme => ({
  showlayout:{
    display: 'block',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    textAlign: 'center',
  },
  flexContainer:{
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 'auto',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
  },
  iconStyle:{
    fontSize: '1em',
    padding: '0 0.2em 0 0',
    color: 'grey',
    textAlign: 'left',
  },
  rightPadding:{
    padding: '0 1em 0 0',
    color: 'grey',
    fontSize: '1em',
    textAlign: 'center',
  },
  name:{
    fontSize: '1.3em',
  },
  title:{
    fontSize: '1em',
    margin: '0 0 1em 0',
  },
  class_name:{
    fontSize: '1.3em',
    margin: '1em 0 0 0',
  },
  divider:{
    margin: '0.5em 0 0.5em 0'
  }
})

const AVATAR_SIZE = 120

const ProfileShowActions = ({ basePath, data, resource }) => (
    <CardActions style={{index:-2}}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

const ShowAvatar = ({ record, source }) => {
  return (
    <Avatar
      src={record[source] + '?height=' + AVATAR_SIZE}
      style={{width:AVATAR_SIZE,height:AVATAR_SIZE,display: 'block',margin: 'auto'}}
    />
  );
};

const CreateUsageField = ({ record, translate }) => (
    <div>
      {translate(record['usageType'])} {record['length']?record['length'] + ' ' + translate('mins'):''}
    </div>
);

const CreateBalanceField = ({ record, translate }) => (
    <div>
      {record['newBalance']&&record['oldBalance']&&(record['newBalance']>record['oldBalance'])? record['newBalance'] + ' (+':''}
      {record['newBalance']&&record['oldBalance']&&(record['newBalance']==record['oldBalance'])? record['newBalance'] + ' (-':''}
      {record['newBalance']&&record['oldBalance']&&(record['newBalance']<record['oldBalance'])? record['newBalance'] + ' (':''}
      {record['oldBalance']&&record['newBalance']? record['newBalance']-record['oldBalance'] + ')' :''}
    </div>
);

const MyDatagridBody = props => <DatagridBody {...props} ids={props.ids?props.ids.reverse():[]} row={<DatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const ProfileShow = ({ classes, translate, ...props }) => (
    <Show
      id="mydata"
      resource="mydata"
      basePath="/my-profile"
      title="My profile" {...props}
      className={classes.showlayout}>
      <SimpleShowLayout className={classes.showlayout}>
          <ShowAvatar source="profile_picture" />
          <TextField source="username" addLabel={false} className={classes.name}/>
          <TextField source="line_name" addLabel={false} className={classes.title}/>
          <TextField source="email" addLabel={false} className={classes.title}/>
          <Divider variant="middle" className={classes.divider}/>
          <ArrayField source="usage" addLabel={false}  >
            <MyDatagrid>
                <TextField
                  type="datetime-local"
                  source="datetime"
                  label={translate('resources.profile.datetime_left')}/>
                <CreateUsageField translate={translate} label={translate('resources.profile.type_used')} />
                <CreateBalanceField translate={translate} label={translate('resources.customers.fields.balance')}/>
            </MyDatagrid>
          </ArrayField>
      </SimpleShowLayout>
    </Show>
);

export default withStyles(styles)(translate(ProfileShow));
